.featured-post {
  height: 400px;
  padding: 20px;
  margin-bottom: 20px;
  background-image: linear-gradient(135deg, #83a4d4, #b6fbff);
  color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.featured-post h1 {
  font-size: 60px;
  color: #464444;
  text-align: left;
}

.featured-post h2 {
  font-size: 50px; /* Increased font size */
  text-align: left;
  color: white;
}

.blog-grid,
.blog-grid-bottom {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 15px;
}

.blog-grid-bottom {
  margin-top: 20px;
}

.blog-grid-bottom .blog-post-tile {
  max-width: 100%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.blog-grid-bottom .blog-post-tile:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.blog-content {
  padding: 80px;
  background-color: #f8f2a8;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.blog-content ul ul {
  padding-left: 2em;
  list-style-type: disc;
}

.blog-content{
  margin: 0 auto;
}

.blog-content img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.blog-content p,
.blog-content li {
  font-size: 24px;
  text-align: justify;
  line-height: 1.5;
  color: #555;
  margin-bottom: 1.5rem;
}

.blog-content h1,
.blog-content h2,
.blog-content h3,
.blog-content h4 {
  font-family: 'Arial Rounded MT', sans-serif;
  color: #464444;
  margin-top: 60px;
  margin-bottom: 60px;
}

.blog-content h1 {
  font-size: 50px; /* Set font size as requested */
  line-height: 1.2;
}

.blog-content h2 {
  font-size: 40px;
}

.blog-content h3 {
  font-size: 30px;
}

.blog-content h4 {
  font-size: 20px;
}

.blog-content a {
  color: rgb(0, 145, 255);
  text-decoration: none;
}


pre {
  font-size: 18px;
  font-family: consolas, monospace;
}

@media only screen and (max-width: 768px) {
  .featured-post {
    height: auto;
    padding: 15px;
  }

  .blog-grid,
  .blog-grid-bottom {
    gap: 15px;
    padding: 10px;
  }

  .blog-content {
    padding: 50px;
  }

  .blog-content h1 {
    font-size: 40px; /* Adjust for mobile */
  }

  .blog-content p,
  .blog-content li {
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .blog-content {
    padding: 20px;
  }

  .blog-content img {
    margin: 0 auto;
    max-width: 100%;
  }

  .blog-content h1 {
    font-size: 30px;
  }

  .blog-content h2 {
    font-size: 24px;
  }

  .blog-content h3 {
    font-size: 18px;
  }

  .blog-content p,
  .blog-content li {
    font-size: 18px;
  }
}
