.share-button {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    text-transform: none;
}

.share-button:hover {
    background-color: #0056b3;
}

.share-menu .MuiPaper-root {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    color: white;
}

.menu-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
}

.menu-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.menu-icon {
    margin-right: 8px; 
  }
  
  .menu-text {
    vertical-align: middle; 
  }
  