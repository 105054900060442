/* General Tutorial Content Styling */
.tutorial-content {
  padding: 0 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: #333; /* Dark gray text for readability */
  min-height: 150vh; /* Ensure the background covers the full viewport height */
  width: 100%; /* Make sure it covers the full width of the page */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Headings with Font Size and Weight */
.tutorial-content h1 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 16px;
  line-height: 1.2;
}

.tutorial-content h2 {
  font-size: 1.75em;
  font-weight: bold;
  margin-bottom: 14px;
  line-height: 1.3;
}

.tutorial-content h3 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 12px;
  line-height: 1.4;
}

.tutorial-content h4 {
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1.5;
}

.tutorial-content h5 {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 8px;
  line-height: 1.6;
}

/* Paragraphs and Lists */
.tutorial-content p, 
.tutorial-content ul, 
.tutorial-content ol, 
.tutorial-content li {
  font-size: 1em; /* Base font size for readability */
  line-height: 1.7; /* Line height for readability */
  margin-top: 10px;
  margin-bottom: 16px;
  color: #333;
}

.tutorial-content ul, 
.tutorial-content ol {
  padding-inline-start: 40px;
  margin-inline-start: 0;
  margin-inline-end: 0;
  list-style-position: inside; /* Ensures consistent alignment */
}

/* Links and Emphasis */
.tutorial-content a {
  text-decoration: underline;
  text-underline-offset: .2rem;
  color: #0969da;
  cursor: pointer;
}

.tutorial-content strong {
  color: black;
}

/* Common Flexbox Styling for All Elements with Icons */
.tutorial-content .candidate-thought,
.tutorial-content .question,
.tutorial-content .interviewer-response,
.tutorial-content .commentary {
  display: flex; /* Use flexbox to align items */
  align-items: center; /* Center align icons and text vertically */
  background-color: var(--highlight-bg);
  border-left: 4px solid var(--secondary-color); /* Default border color */
  padding: 10px 15px; /* Padding inside the container */
  margin: 15px 0;
  color: var(--secondary-color); /* Default text color */
}

/* Icon Styling */
.tutorial-content .candidate-thought::before,
.tutorial-content .question::before,
.tutorial-content .interviewer-response::before,
.tutorial-content .commentary::before {
  content: ''; /* Default to no content initially */
  font-size: 1.2em;
  margin-right: 10px; /* Space between icon and text */
}

.tutorial-content .candidate-thought::before {
  content: "\1F4AC"; /* Thought bubble icon */
  color: var(--secondary-color); /* Icon color */
}

.tutorial-content .question::before {
  content: "\2753"; /* Question mark icon */
  color: var(--primary-color); /* Icon color */
}

.tutorial-content .interviewer-response::before {
  content: "\1F5E8"; /* Speech bubble icon */
  color: var(--border-color); /* Icon color */
}

.tutorial-content .commentary::before {
  content: "\1F4A1"; /* Light bulb icon */
  color: var(--note-color); /* Icon color */
}

/* Definitions with Toggle Visibility */
.tutorial-content .definition {
  cursor: pointer;
  color: var(--primary-color); /* Dark gray for interactive text */
  background-color: #FFFDD0;
  border-radius: 15px;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.tutorial-content .prompt{
  color: #d83939;
  font-weight: bold;
}

.tutorial-content li p{
  display: inline;
}

@media (min-width: 1200px) {
  .tutorial-content {
    padding: 0 10px;
    color: #333;
    min-height: 50vh;
    width: 100%;
    box-sizing: border-box;
  }
}



@media (max-width: 589px) {
  .tutorial-content {
    width: 85vw;
  }

  .tutorial-content h1 {
    margin-top: 0.5rem;
    font-size: 1.2rem;
  }

  .tutorial-content h2 {
    margin-top: 0.5rem;
    font-size: 1rem;
  }

  .tutorial-content h3 {
    margin-top: 0.5rem;
    font-size: 0.9rem;
  }

  .tutorial-content,
  .tutorial-content p,
  .tutorial-content .definition,
  .tutorial-content ul,
  .tutorial-content .question,
  .tutorial-content .candidate-thought,
  .tutorial-content .interviewer-response,
  .tutorial-content .commentary {
    text-align: start;
  }

  .tutorial-content p,
  .tutorial-content .definition {
    margin-right: 8px;
  }

  .tutorial-content ul {
    padding-inline-start: 5px;
  }

  .tutorial-content .question,
  .tutorial-content .candidate-thought,
  .tutorial-content .interviewer-response,
  .tutorial-content .commentary {
    margin: -20px 0;
  }

  .tutorial-content .commentary {
    margin-top: -27px;
  }
}

