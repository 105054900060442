/* General Layout */
:root {
  --text-color: #333;
}

.assessment-feedback-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

/* Headings */
.h2-evaluate {
  font-size: 1.8rem;
  font-family: "Arial Rounded MT", sans-serif;
  font-weight: 700;
  margin-bottom: 0.7rem;
  color: var(--text-color);
  text-align: center;
}

.h3-key {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 10px;
  text-align: center;
}

.ul-key {
  list-style-type: disc;
  padding-left: 20px;
}

.ul-key li {
  margin-bottom: 5px;
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
  margin-left: 15px; /* Adds spacing between bullet and text */
}

/* Container Styles */
.assessment-feedback,
.evaluation-summary,
.assessment-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #fff;
}

/* Evaluation Summary */
.evaluation-summary {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
}

.evaluation-summary::-webkit-scrollbar {
  height: 8px;
}

.evaluation-summary::-webkit-scrollbar-thumb {
  background: #304ef4;
  border-radius: 5px;
}

.evaluation-summary::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.evaluation-summary table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  /* Removed table-layout: fixed for flexibility */
}

.evaluation-summary table thead {
  background-color: #f4f4f4;
}

.evaluation-summary table th, 
.evaluation-summary table td {
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 0.9rem;
}

.evaluation-summary table tbody tr:hover {
  background-color: #f9f9f9;
}

/* Evaluation Colors */
.evaluation-strong, .evaluation-high {
  color: #02b875;
  font-weight:bold;
}

.evaluation-moderate, .evaluation-medium{
  color: rgb(146, 99, 11);
  font-weight:bold;
}

.evaluation-weak, .evaluation-low {
  color: #e66262;
  font-weight:bold;
}

/* Optimized Animated Highlights */
@keyframes rowHighlight {
  0% {
    background-color: rgba(48, 78, 244, 0.1);
  }
  50% {
    background-color: rgba(48, 78, 244, 0.2);
  }
  100% {
    background-color: rgba(48, 78, 244, 0.1);
  }
}

.evaluation-summary table tbody tr {
  /* Removed transform: scale(...) for better compatibility */
  animation: rowHighlight 2s ease-in-out;
  animation-iteration-count: 1; /* Highlight only once */
}

/* Key Styles */
.key-explanations {
  font-family: "Trebuchet MS", sans-serif;
  margin-top: 20px;
  width: 100%;
  font-size: 0.9rem;
}

/* Assessment Dashboard */
.assessment-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 0.9rem;
}

.assessment-dashboard button {
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin: 10px 0;
  font-size: 0.9rem;
}

.assessment-dashboard button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

/* Disabled Buttons */
.assessment-dashboard button[disabled] {
  background-color: #bdbdbd;
  cursor: not-allowed;
  box-shadow: none;
}

/* Optimized Highlighted Buttons */
@keyframes highlightButton {
  0% {
    background-color: #ffffff;
    color: #000000;
  }
  50% {
    background-color: #304ef4;
    color: #ffffff;
  }
  100% {
    background-color: #ffffff;
    color: #000000;
  }
}

.highlight-button {
  animation: highlightButton 2s ease-in-out;
  animation-iteration-count: 2; /* Just 2 iterations */
}

/* Modal Styles */
.share-results-container {
  margin: 20px;
  text-align: center;
}

.share-results-container Button {
  margin-top: 12px;
}

.notification-container {
  margin-bottom: 10px;
  font-size: 0.9rem;
}

/* Mobile Responsiveness */
@media (max-width: 800px) {
  .assessment-feedback-container {
    display: flex;
    flex-direction: column;
  }

  .evaluation-summary {
    width: 100%;
  }

  .h2-evaluate {
    font-size: 1.5rem;
  }

  .h3-key {
    font-size: 1.3rem;
  }

  .evaluation-summary table th,
  .evaluation-summary table td {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .assessment-feedback-container {
    padding: 10px;
  }

  .h2-evaluate {
    font-size: 1.3rem;
  }

  .h3-key {
    font-size: 1.1rem;
  }

  .assessment-dashboard button {
    font-size: 0.85rem;
  }

  .evaluation-summary table td {
    font-size: 0.8rem;
  }

  .ul-key li {
    font-size: 0.9rem;
    margin-left: 10px; 
  }
}
