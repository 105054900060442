html,
body,
#root {
  height: 100%;
}

.footer {
  background-color: #f5f5f5;
  padding: 1rem;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
}

.footer-links {
  display: flex;
  flex-direction: row;
}

.footer-links a {
  color: #333;
  text-decoration: none;
  margin: 0 1rem;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.footer-links a:hover {
  text-decoration: underline;
}

.home {
  background-color: #777e68;
}

.footer-links.home a {
  color: #f3ecd8;
}

@media screen and (max-width: 725px) {
  .footer-links {
    flex-direction: column;
  }
  
  .footer-links a{
    text-align: left;
  }
}
