/* Container for the sticky notes to apply grid layout */
.multi-modal-testimonials {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns, each taking equal width */
  gap: 20px; /* Space between grid items */
  margin: 20px 0; /* Margin around the grid container */
  padding: 10px;
}

/* Sticky Note Style for Text Testimonials */
.testimonial-card.sticky-note {
  background-color: #fffb8f; /* Light yellow color */
  padding: 20px;
  border-radius: 10px; /* Curved corners */
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  font-family: 'Comic Sans MS', 'Comic Sans', 'Nunito', 'Verdana', cursive, sans-serif; 
  transform: rotate(-2deg); /* Slight rotation to mimic sticky note */
  position: relative; /* Ensure the pseudo-elements are positioned correctly */
  width: 100%; /* Make each note take full width of the grid column */
  box-sizing: border-box; /* Ensure padding and border are included in width */
}

/* Additional styling for text content */
.testimonial-card.sticky-note p, 
.image-testimonial-card.sticky-note p {
  font-size: 1rem;
  color: #333; /* Darker color for text contrast */
  margin-bottom: 10px;
}

/* Styling for user display name and company information */
.testimonial-card.sticky-note .testimonial-footer,
.image-testimonial-card.sticky-note .testimonial-footer,
.video-testimonial-card.sticky-note .testimonial-footer {
  font-size: 0.9rem;
  color: #555; /* Slightly lighter color for footer text */
  margin-top: 10px;
  display: flex;
  align-items: center;
}

/* Sticky Note Style for Image Testimonials */
.image-testimonial-card.sticky-note {
  text-align: center; /* Center-align content */
  background-color: #fffb8f; /* Light yellow color */
  padding: 10px;
  border-radius: 10px; /* Curved corners */
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  font-family: 'Comic Sans MS', 'Comic Sans', 'Nunito', 'Verdana', cursive, sans-serif; 
  transform: rotate(-2deg); /* Slight rotation to mimic sticky note */
  position: relative; /* Ensure the pseudo-elements are positioned correctly */
  width: 100%; /* Make each note take full width of the grid column */
  box-sizing: border-box; /* Ensure padding and border are included in width */
  
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Additional styling for image testimonials */

.image-testimonial-card.sticky-note .testimonial-image {
  border: 1px solid black;
  border-radius: 10px;
}

/* Optional: Apply the same sticky tape effect */
.image-testimonial-card.sticky-note::before,
.video-testimonial-card.sticky-note::before {
  content: '';
  position: absolute;
  width: 50px;
  height: 15px;
  background-color: #e0e0e0;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}


.testimonial-card.sticky-note .user-display-name {
  font-weight: bold;
  margin-right: 10px;
}

.testimonial-card.sticky-note .company-name {
  display: flex;
  align-items: center;
}

.testimonial-footer .company-name img {
  height: 40px;
  margin-left: 5px;
}

/* Optional: Pseudo-element to add a sticky tape effect */
.testimonial-card.sticky-note::before {
  content: '';
  position: absolute;
  width: 50px;
  height: 15px;
  background-color: #e0e0e0;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

/* Below are for video type testimonials */
.video-testimonial-card.sticky-note {
  background-color: #fffb8f; /* Light yellow color */
  padding: 20px;
  border-radius: 10px; /* Curved corners */
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  font-family: 'Comic Sans MS', 'Comic Sans', 'Nunito', 'Verdana', cursive, sans-serif; 
  transform: rotate(-2deg); /* Slight rotation to mimic sticky note */
  position: relative; /* Ensure the pseudo-elements are positioned correctly */
  width: 100%; /* Make each note take full width of the grid column */
  box-sizing: border-box; /* Ensure padding and border are included in width */
}

.video-thumbnail {
  width: 100%;
  border-radius: 8px;
}

.video-testimonial-card.sticky-note .video-caption {
  overflow-wrap: break-word; /* Ensures text wraps properly */
  color: #333;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  padding: 1px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.play-button-overlay button {
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
}

.play-button-overlay button:focus {
  outline: none;
}


/* Carousel Feature */
.carousel-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel-slides {
  display: flex;
  transition: transform 0.5s ease-out;
  transform: translateX(var(--carousel-translate, 0));
}

.carousel-page {
  min-width: 100%; /* Each page takes the full width of the container */
}

.carousel-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Adjust for a 3x2 grid */
  gap: 20px;
  padding: 10px;
  margin: 20px 0;
}

.carousel-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  padding: 0 1rem;
  z-index: 10; /* Ensures controls are above other elements */
}

.carousel-button {
  background-color: rgba(0, 0, 0, 0.2); /* Darker background for visibility */
  color: white;
  border: none;
  padding: 0.1rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow for depth */
}

.carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.9); /* Darker on hover for better contrast */
}

.social-proof-container .carousel-indicators {
  position: absolute;
  bottom: 5px; /* Increased distance from the bottom */
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  z-index: 10;
}

.indicator-dot {
  width: 0.5rem;
  height: 0.75rem;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  transition: background-color 0.3s;
}

.indicator-dot.active {
  background-color: #333; /* Darker color for active indicator */
  transform: scale(1.5); /* Slightly larger to indicate active state */
}

@media (max-width: 820px) {

  .image-testimonial-card.sticky-note {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .carousel-grid {
      grid-template-columns: 1fr;
  }

  .carousel-button {
      padding: 0.4rem;
  }

  .multi-modal-testimonials {
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 5px;
  } 
}