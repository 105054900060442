:root {
  --dark-color-1: #2b2929;
  --light-color-1: #f4d17a;
}

/* Create shading on the background to let content standout instead */
.dashboard-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.dashboard-container::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.Home-header {
  font-family: Arial Rounded MT, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  display: block;
  line-height: 1.1;
  margin-bottom: 10px;
}

.Home-h3 {
  font-size: 1.17em;
  font-weight: bold;
}

/* section 1 */
.section1 {
  display: grid;
  grid-template-rows: 1fr auto;

  background-image: url("../img/backg_images/home.webp");
  background-size: cover; /* cover entire page */
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
}

/* Web Statement */
.webStatement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  /* border: 2px solid black; */
  margin-top: 70px;
}

.webStatement h1,
.webStatement h2 {
  color: #f4d17a;
  margin-left: 15%;
  z-index: 5;
}

@keyframes colorChange {
  0% {
    color: #f4ca7a;
  }
  50% {
    color: white;
  }
  100% {
    color: #f4ca7a;
  }
}

.webStatement h3 {
  animation: colorChange 5s infinite;
  margin-left: 15%;
  /* background-color: #ffffff; */
  padding: 10px;
  border-radius: 5px;
  z-index: 5;
}

/* CompanyCredentials */
.companyCredentials {
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* border: 2px solid black; */
  margin: auto;
}

.companyCredentials h2 {
  color: #f4d17a;
  z-index: 5;

  /* margin: 0 auto; */
  text-align: center;
  line-height: 1.3;
  font-family: "Arial Rounded MT", sans-serif;
  font-weight: 700;
}

/* List */
.bulletpoints {
  list-style: none;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--dark-color-1);
  margin-top: 10px;
  margin-left: 15%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.bulletpoints li {
  padding-left: 20px; /* Space before the bullet point text */
  margin-bottom: 10px; /* Space between bullet points */
  position: relative;
  color: #b0e0e6;
  z-index: 5;
}

.bulletpoints li::before {
  content: ""; /* Your chosen bullet point */
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #76b852; /* Bullet point color */
}

/* Call to Action btns */
.btn-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* border: 2px solid black; */
  align-self: center;
}

.home-page-secondary-call-to-action-btn,
.home-page-primary-call-to-action-btn {
  border-radius: 15px;
  cursor: pointer;
  z-index: 3;
}

.home-page-secondary-call-to-action-btn {
  padding: 10px;
  background-image: linear-gradient(
    135deg,
    #a0a89a 20%,
    #77806e 80%,
    #4f5748 100%
  );
  border: 1px solid #77806e;
  color: #f3ecd8;
  font-size: 1.6rem;
  width: fit-content;
  margin: auto;
}

.home-page-secondary-call-to-action-btn:hover {
  background-color: #77806e;
  opacity: 0.8;
}

.home-page-primary-call-to-action-btn {
  padding: 5px;
  background-image: linear-gradient(
    135deg,
    #ff7f24 20%,
    #cc5500 80%,
    #993300 100%
  );
  border: 1px solid #cc5500;
  color: #fff8e7;
  font-size: 2rem;
  box-shadow: 5px 10px 20px black;
}

.home-page-primary-call-to-action-btn:hover {
  background-color: #cc5500;
  opacity: 0.8;
}

.home-page-primary-call-to-action-btn .subtitle {
  font-size: 0.8em; /* Adjust the font size as needed */
  padding: 1px 3px; /* Adjust the padding to make the button smaller */
  display: inline-block; /* Ensure the span behaves like an inline element */
  border-radius: 5px;
  background-image: linear-gradient(
    135deg,
    #f9e5b3 20%,
    #f4d17a 80%,
    #d4b15a 100%
  );
  color: var(--dark-color-1);
  font-style: italic; /* Italicize the text */
  transform: rotate(-0.8deg); /* Slight tilt to the left */
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.demo-video {
  width: 640px;
  height: 360px;
  display: block;
  margin: auto;
}

/* Features Section */
.section2 {
  background-image: url("../img/backg_images/home.webp");
  background-size: cover; /* cover entire page */
  background-position: center;
  background-repeat: no-repeat;
  /* height: 100vh; */
  position: relative;
}

.bento-box {
  display: grid;
  grid-template-columns: repeat(3, minmax(min-content, max-content));
  grid-template-areas:
    "feature1 feature1 feature2"
    "feature3 feature4 feature4"
    "feature5 feature5 feature6";
  grid-gap: 20px;
  justify-content: center;
  padding: 40px 0;
}

.item1 {
  grid-area: feature1;
  display: flex;
  gap: 50px;
}

.item1 > .itemText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item2 {
  grid-area: feature2;
  display: flex;
  gap: 10px;
  width: fit-content;
}

.item2 > .itemText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 170px;
}

.item3 {
  grid-area: feature3;
  display: flex;
  gap: 10px;
  width: fit-content;
}

.item3 > .itemText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 170px;
}

.item4 {
  grid-area: feature4;
  display: flex;
  gap: 50px;
}

.item4 > .itemText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item5 {
  grid-area: feature5;
  display: flex;
  gap: 50px;
}

.item5 > .itemText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item6 {
  grid-area: feature6;
  display: flex;
  gap: 10px;
  width: fit-content;
}

.item6 > .itemText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 170px;
}

.itemText h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}

.grid-item {
  border: 1px solid black;
  height: fit-content;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  z-index: 3;
  background-color: rgba(74, 50, 47, 0.8);
}

.grid-item:hover {
  transform: translateY(-5px); /* Slight raise effect on hover */
}

.grid-item h3 {
  color: #f3ecd8;
  margin: 0;
}

.grid-item p {
  color: #f4d17a;
  margin: 0;
}

.grid-item a {
  color: #b0e0e6;
  text-decoration: none;
}

.grid-item img {
  height: 150px;
  width: 150px;
}
/* Section 3 */

.section3 {
  background-image: url("../img/backg_images/home.webp");
  background-size: cover; /* cover entire page */
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/* testimonials section */
.leftSideSection3 {
  display: flex;
  flex-direction: column;
  width: 55%;
  margin-top: 42px;
  justify-content: center;
}

.testimonial-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.testimonials {
  width: 100%;
  padding: 10px 18px;
  background-color: rgba(75, 52, 48, 0.3);
  border-radius: 20px;
  position: relative;
  align-self: center;

  display: grid;
  grid-template-columns: repeat(
    2,
    1fr
  ); /* Ensure equal width for each column */
  grid-template-rows: 1fr; /* Single row */
  grid-gap: 10px;
  z-index: 3;
}

.testimonials > h3 {
  position: absolute;
  top: -30px;
  left: 10px;
  color: #f3ecd8;
  font-size: 1.5rem;
  font-family: "Raleway";
  font-weight: bold;
}

.testimony {
  border: 1px solid black;
  border-radius: 10px;
  text-align: start;
  background-color: #f3ecd8;
  padding: 10px 10px;
  font-family: "Manrope";

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  transition: 0.1s ease-in-out;
}

.testimony p {
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.testimony > .nameTag {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.nameTag > .name {
  font-size: 0.9rem;
  font-weight: 500;
}

.nameTag > .jobTitle {
  font-size: 0.8rem;
}

.company-logo {
  width: 50px; /* Adjust the size as needed */
  margin-top: 5px;
}

.testimony:nth-child(odd) {
  transform: translateY(-5px);
}

.testimony:nth-child(even) {
  transform: translateY(5px);
}

.testimony:nth-child(odd):hover {
  transform: translateY(-2px);
}

.testimony:nth-child(even):hover {
  transform: translateY(2px);
}

.leftSideSection3 .pagination-controls {
  margin-top: 0;
  margin-right: 0;
  margin-left: 20px;
}

/* Right Side Section3 Groupings */
.rightSideSection3 {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 45%;
}

.rightSideSection3 .companyCredentials {
  margin: 0;
}

.rightSideSection3 .pagination-controls {
  margin-right: 50px;
}

/* HomePage specific FAQ section */
.rightSideSection3 .FAQ {
  min-width: 600px;
}

.rightSideSection3 .pagination-controls span {
  font-size: bold;
  color: #f3ecd8;
}

/* Old Code */
.first-row {
  display: grid;
  grid-template-columns: 60% 40%;
  height: 430px;
  margin-bottom: 10px;
}

.description {
  background-color: #2b70c9;
  color: white;
  border-radius: 20px;
  padding: 25px;
}

.description h2.feature-selling-point {
  color: white;
  padding: 10px;
  background-color: #2867b9;
  border-radius: 10px;
}

.description p {
  color: white;
}

.feature-image-container {
  padding: 20px;
  border-radius: 20px;
}

.second-row {
  display: flex;
  overflow-x: auto;
}

.feature-div {
  display: flex;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 20px;
  padding: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 150px;
}

.feature-div img {
  width: 100px;
  height: auto;
  border-radius: 10px;
}

.first-column,
.second-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonials img.company-logo {
  max-height: 90px;
  max-width: 90px;
}

/* New Mobile Media Query */

/* @media (max-width: 600px) {
  h1,
  h2,
  h3 {
    font-size: 1.2rem;
  }

  p,
  .faq-item .question p,
  .testimony p,
  .grid-item p {
    font-size: 0.9rem;
  }
} */

/* mobile media query*/
/* @media (max-width: 1400px) {
  .first-row {
    grid-template-columns: 60% 40%;
  }
} */

/* Need to adjust for 1400px - 1024px width range */
@media (max-width: 1400px) {
  .rightSideSection3 .FAQ {
    min-width: 300px;
  }

  .rightSideSection3 .FAQ h2 {
    font-size: 1.25rem;
  }

  .rightSideSection3 {
    padding: 10px;
  }

  /* Section 1 */
  .webStatement {
    margin-top: 40px;
  }

  .first-row .webStatement h1 {
    font-size: 2rem;
    margin-bottom: 0px;
  }

  .section1 .first-row .webStatement .Home-h3 {
    font-size: 1rem;
  }

  .section1 .first-row .webStatement .bulletpoints {
    padding: 10px;
    margin-top: 5px;
  }

  .section1 .first-row .webStatement .bulletpoints li {
    margin-bottom: 5px;
  }

  .home-page-secondary-call-to-action-btn {
    font-size: 1.4rem;
  }
  
  .home-page-primary-call-to-action-btn {
    font-size: 1.75rem;
  }
}

@media (max-width: 1200px) {
  .home-page-primary-call-to-action-btn {
    font-size: 1.5rem;
  }

  .home-page-secondary-call-to-action-btn {
    font-size: 1.2rem;
  }

  .first-row .webStatement h1 {
    font-size: 1.75rem;
  }
}


@media (max-width: 1024px) {
  .section1 {
    height: 100%;
  }

  .first-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin: 0;
    padding: 20px 0;
  }

  .webStatement {
    margin: 0;
    align-items: center;
  }

  .first-row .webStatement h1 {
    padding: 0;
    font-size: 2rem;
  }

  .webStatement {
    padding: 0;
  }

  .bulletpoints li {
    font-size: 1rem;
  }

  .demo-video {
    width: 100%;
    height: auto;
  }

  .companyCredentials h2 {
    font-size: 1.5rem;
  }

    /* Section 3 */
    .leftSideSection3,
    .rightSideSection3 {
      width: 90%;
    }
  
    .rightSideSection3 .pagination-controls {
      margin-right: 0;
    }
  
    .section3 {
      flex-direction: column; /* Stack .testimonials and .FAQ on top of each other */
      align-items: center;
      height: auto;
      padding: 40px 10px;
    }
  
    .testimonials {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 0;
    }
  
    .testimonials > h3 {
      font-size: 1.2rem;
    }
  
    .testimony:hover {
      transform: none;
    }
  
    .testimony:nth-child(even),
    .testimony:nth-child(odd) {
      transform: none;
    }
  
    .testimony p {
      font-size: 1rem;
    }
  
    .rightSideSection3 .FAQ {
      width: 100%;
      min-width: 300px;
    }

    .rightSideSection3 .questions .faq-item .question h3 {
      font-size: 1.2rem;
    }
  
    .leftSideSection3 .pagination-controls {
      margin-top: 5px;
      margin-left: 0;
    }
}

@media (max-width: 820px) {
  .section2 {
    height: 100%;
  }
  .bento-box {
    align-items: center;
    padding: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: none;
  }

  .grid-item {
    gap: 0;
    grid-area: auto;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: auto;
    position: relative;
  }

  .grid-item .itemText {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
  }

  .grid-item .itemText.active {
    background: rgba(0, 0, 0, 0.5);
  }

  .grid-item .itemText h3 {
    font-size: 1.1em;
    color: rgba(255, 255, 255, 0.9);

    /* margin-bottom: 170px; */
  }

  .grid-item .itemText h3.active {
    color: rgba(255, 255, 255, 1);
    /* margin-bottom: 0; */
  }

  .grid-item .itemText .box p {
    font-size: 0.9rem;
  }

  .grid-item .itemText .box a {
    font-size: 0.8rem;
  }

  .grid-item .itemText .box.active {
    display: block;
  }
  .grid-item .itemText .box {
    display: none;
  }
  /* Section 3 */
  .leftSideSection3,
  .rightSideSection3 {
    width: 90%;
  }

  .rightSideSection3 .pagination-controls {
    margin-right: 0;
  }

  .section3 {
    flex-direction: column; /* Stack .testimonials and .FAQ on top of each other */
    align-items: center;
    height: auto;
    padding: 40px 10px;
  }

  .testimonials {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0;
  }

  .testimonials > h3 {
    font-size: 1.2rem;
  }

  .testimony:hover {
    transform: none;
  }

  .testimony:nth-child(even),
  .testimony:nth-child(odd) {
    transform: none;
  }

  .testimony p {
    font-size: 1rem;
  }

  .rightSideSection3 .FAQ {
    width: 100%;
    min-width: 300px;
  }

  .leftSideSection3 .pagination-controls {
    margin-top: 5px;
    margin-left: 0;
  }
}

@media (max-width: 600px) {
  .second-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow-x: auto;
  }

  .feature-div {
    display: flex;
    width: 300px;
    height: 120px;
    margin-bottom: 5px;
    padding: 15px;
  }

  .webStatement h1,
  .webStatement h2,
  .webStatement h3,
  .bulletpoints {
    margin-left: 0px;
  }
  .bulletpoints {
    margin: 10px;
  }
}

@media (min-width: 599px) and (max-width: 1000px) {
  .bulletpoints {
    padding: 15px;
    margin-top: 10px;
    margin: 10px;
  }
}
