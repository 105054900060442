/* Subscriptions.css */

:root {
    --main-font-color: black;
    --secondary-font-color: #0056b3;
    --main-background-image: linear-gradient(135deg, #83a4d4, #b6fbff);
    --secondary-background-image: linear-gradient(135deg, #e0f1fd 20%, #c6e3fa 80%, #a0c7e6 100%);
    --tertiary-background-image: linear-gradient(135deg, #e0f1fd 20%, #c6e3fa 80%, #a0c7e6 100%);
}

.welcome-container p{
    margin-top: 5px;
    margin-left: 20px;
    font-weight: bold;
    color: var(--main-font-color);
}

.subscription-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns in the first row */
    grid-template-rows: auto auto;  /* Automatically sized rows */
    gap: 20px; /* Space between grid items */
    background-image: var(--main-background-image);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Adds subtle shadow for depth */
    /* Rounded corners */
    padding: 20px;
    /* Padding around content */
    margin: 20px;
    /* Margin around sections for space */
}

/* Ensure that the two items in the first row span one column each */
.subscription-details-view, .resource-availability-view {
    grid-column: span 1; /* Each takes one column */
}

/* Full width for the form in row 2 */
.premium-subscription-view{
    grid-column: span 2; /* Takes up both columns in the first row */
}

.subscription-details-view, .resource-availability-view, .subscription-form-view{
    border-radius: 40px;
    border: solid 1px #eceaea;
    padding: 40px;
    box-shadow: 0 2px 5px rgba(10, 4, 4, 0.938);
    background-image: var(--secondary-background-image);
}


/* Keep the rest of your original CSS intact */
.subscription-container p {
    color: #333;
    /* Dark grey color for text for better readability */
    line-height: 1;
    /* Increased line height for readability */
}

.subscription-container h2 {
    color: var(--main-font-color);
    /* Brand color for headings */
    margin-bottom: 20px;
    /* Space below headings */
}

.subscription-container input[type="text"],
.subscription-container input[type="email"],
.subscription-container select {
    width: 100%;
    /* Full width controls */
    padding: 10px;
    /* Padding inside inputs */
    margin-top: 5px;
    /* Margin top for spacing */
    margin-bottom: 15px;
    /* Margin bottom to separate elements */
    border: 1px solid #eceaea;
    /* Subtle border */
    border-radius: 4px;
    /* Rounded borders */
    background-color: white;
}

.subscription-container input[type="email"] {
    background-color: #eee;
    /* Light grey background for read-only fields */
    cursor: not-allowed;
    /* Cursor indicates non-editability */
}

.subscription-container button {
    background-color: var(--secondary-font-color);
    /* Brand color */
    color: white;
    /* White text */
    border: none;
    /* No border */
    padding: 10px 20px;
    /* Padding for larger clickable area */
    border-radius: 5px;
    /* Rounded corners */
    cursor: pointer;
    /* Indicates button */
    transition: background-color 0.3s;
    /* Smooth transition for hover effect */
}

.subscription-container button:hover {
    background-color: #004494;
    /* Darker shade on hover for interaction feedback */
}

.subscription-container button:disabled {
    background-color: #ccc;
    /* Greyed out */
    cursor: default;
    /* Default cursor on disabled button */
}

.subscription-container label {
    display: block;
    /* Makes label a block to encompass the full width */
    margin-bottom: 5px;
    /* Space between label and input */
}

.subscription-status-modal-view-container .modal-close-button {
    background-color: grey;
}

.payment-cancelled-container h1 {
    color: rgb(226, 67, 67);
}

.payment-success-container h1 {
    color: var(--secondary-font-color);
}


.subscription-options-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 40px;
}

.subscription-option-features {
    max-width: 700px;
    border-radius: 10px;
    border: 1px solid gray;
    box-shadow: 0 4px 8px rgb(20, 8, 8);
    padding: 20px 25px;
    background-image: var(--tertiary-background-image);

    /* Center everything inside */
    display: flex;
    flex-direction: column;
}

.features-list {
    /* Keep features stacked vertically */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Left-align the checkmarks and text */
    gap: 6px; /* Add spacing between features */
    width: 100%; /* Ensure it takes the full width of the parent */
}

.features-list p.feature {
    position: relative;
    padding-left: 2em; 
    /* Enough space to accommodate the checkmark on the left. */
    margin: 0.3em 0; 
    /* Tiny spacing between feature items */
  }
  
.features-list p.feature::before {
    content: "✅";
    position: absolute;
    left: 0.4em; 
    top: 0; /* Align the checkmark with the top of the text line */
    transform: none; /* Remove the translateY that centers it */
}


.subscription-option-features p.feature {
    display: flex; /* Flexbox to align checkmark and text */
    font-family: 'Comic Sans MS', 'Comic Sans', 'Nunito', 'Verdana', cursive, sans-serif;
    margin-bottom: 4px;
    margin-top: 0px;
    width: 100%; /* Ensure full width for alignment */
}

.subtitle.members-social-proof{
    color: white;
}

/* Add this media query for mobile view */
@media (max-width: 768px) {
    .subscription-container {
        grid-template-columns: 1fr; /* Change to one column */
        grid-template-rows: auto auto auto; /* Stack the rows */
    }

    .subscription-options-container{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        padding: 40px;
    }

    /* Ensure each item takes the full width in a single column */
    .subscription-details-view, .resource-availability-view, .premium-subscription-view {
        grid-column: span 1; /* Each takes up the full width */
    }

    .features-list{
        width: 100%;
    }
}
