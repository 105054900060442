@keyframes moveLeftToCenter {
    0% {
        left: 0; /* this means that the element will be moved to the right by 0% of its width */
        transform: translateX(0);/*this means that the element will be moved to the left by 0% of its width*/
    }



    100% {
        left: 50%; /* this means that the element will be moved to the right by 50% of its width */
        transform: translateX(-50%); /*this means that the element will be moved to the left by 50% of its width*/
    }
}

.horizontalMotionAnimation {
    position:absolute;
    /* Adjust as needed */
    width: auto;
    /* Adjust based on content */
    animation: moveLeftToCenter 3s forwards;
}


.learning-path {
    font-family: Arial, sans-serif;
    display: grid;
    grid-template-rows: 1fr 3fr;
    gap: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.settings {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #c6e3fa;
    display: flex;
    /* Add this line to enable flex */
    flex-direction: row;
    /* Add this line to enable flex */
    height: 230px;
    justify-content: space-between;
    /* Spaces out the items */
    align-items: center;
}

div.setting {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    margin: 5px;
}

.learning-path-views {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.tasks,
.spaced-repetition {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 700px;
    overflow-y: scroll;

}

.tasks {
    background-color: #2b70c9;
    color: white;
}

.tasks .tasks-container-heading,
.spaced-repetition .spaced-repetition-container-heading {
    color: white;
    font-size: 24px;
    font-weight: bold;
}


.tasks .table-heading,
.spaced-repetition .table-heading {
    color: white;
    font-size: 22px;
    background-color: #2b70c9;
}

/* .tasks tr, .spaced-repetition tr{
    color: white;
    
} */

.tasks a,
.spaced-repetition a {
    color: white;
    text-decoration: none;
    font-size: 18px;
}

.spaced-repetition {
    background-image: linear-gradient(135deg, #83a4d4, #b6fbff);
    color: white;
}

.tasks>div,
.spaced-repetition>div {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 0;
}

button.complete-btn {
    background-color: #4CAF50;
    color: white;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    margin-left: 10px;
}

button.complete-btn:hover {
    background-color: #45a049;
}

.learning-path .alert {
    display: flex;
    background-color: none;
    /*#f7f7f7;*/
    padding: 10px;
    border-radius: 10px;
    /* height: 100px; */
    font-size: 16px;
    align-items: center;
    justify-content: center;
}

.learning-path .info {
    display: flex;
    background-color: none;
    /*#f7f7f7;*/
    padding: 10px;
    font-size: 16px;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1000px) {
    .settings {
        flex-direction: column;
        /* Switch to horizontal for wider screens */
    }

    .horizontalMotionAnimation {
        position:static;
        animation: none;
    }
}

@media (max-width: 800px) {
    .learning-path-views {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 20px;
    }
}

@media (max-width: 600px) {
    .learning-path {
        margin-left: 5px;
        margin-right: 5px;
    }

    .tasks,
    .spaced-repetition {
        height: 500px;

    }
}