:root {
    --bg-color: lightgrey;
    --text-white: #fff;
    --button-color-primary: #0097a9;
    --button-color-secondary: #9191e1;
  }
  
  .mock-interview-buttons-wrapper {
    background-color: var(--bg-color);
    color: var(--text-white);
    padding: 1rem;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .mock-interview-button-primary {
    background-color: var(--button-color-primary) !important;
    color: var(--text-white) !important;
    text-transform: none !important;
    font-size: 1rem;
    padding: 0.75rem 1rem !important;
  }
  .mock-interview-button-secondary {
    background-color: var(--button-color-secondary) !important;
    color: var(--text-white) !important;
    text-transform: none !important;
    font-size: 1rem;
    padding: 0.75rem 1rem !important;
  }
  
  .mock-interview-button:hover {
    opacity: 0.9;
  }
  
  @media (max-width: 600px) {
    .mock-interview-buttons-wrapper {
      padding: 0.5rem;
      gap: 0.75rem;
    }
    .mock-interview-button {
      font-size: 0.9rem;
      padding: 0.5rem 0.75rem !important;
    }
  }
  