.disabled-card {
  color: gray;
  cursor: none;
  opacity: 0.5;
  pointer-events: none;
}

.learning-home {
  display: flex;
  flex-direction: column;
  min-height: 700px;
  background-image: url("../img/learning_images/learning_background_image.jpg");
  background-size: cover;
  color: white;
  text-shadow: 1px 1px 2px black;
}

.learning-menu {
  margin-top: 1rem;
  margin-left: 3rem;
  margin-right: none;
  overflow-x: auto;
}

.learning-title {
  text-align: left;
  white-space: nowrap;
}

.learning-cards {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 230px));
  overflow-x: auto;
  gap: 10px;
  justify-content: center;
  overflow: visible;
  height: 310px;
}

.learning-card-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.learning-card-container:hover {
  transform: scale(1.3);
}

.learning-card {
  border: 3px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: auto;
  width: 180px;
  height: 180px;
  transition: transform 0.3s ease;
}

.learning-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
}

.active-learning-card {
  transform: scale(1.1);
  border: 4px solid #c09d15;
}

.learning-card-title {
  max-width: 100%;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 0.1rem;
}

.active-component-container {
  margin-right: 2rem;
  margin-left: 2rem;
  flex-grow: 1;
}

@media screen and (max-width: 999px) {
  .learning-menu {
    margin-left: none;
    margin-right: none;
  }

  .active-component-container {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

@media screen and (max-width: 819px) {
  .learning-cards {
    justify-content: normal;
  }
}

@media screen and (max-width: 600px) {
  .learning-card-container:hover {
    transform: scale(1.01);
  }
}
