
.data-collection-view-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  text-shadow: none;
}

.assessments-menu {
  margin-top: 10px;
  margin-bottom: 3rem;
  overflow-x: auto;
}

.assessments-top-row {
  display: grid;
  white-space: nowrap;
  grid-template-columns: min-content auto;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.assessments-title {
  text-align: left;
  margin-bottom: 0;

  color: black;
  text-shadow: 1px 1px 2px white;

  position: relative;
  /* Add this to enable stacking of text above the border */
  padding: 0px 30px;
  /* Adjust the padding values */
  /* Add padding to create space between text and border */
  text-align: left;
  /* Center the text within the border */
  background-color: white;
  /* Add a semi-transparent background */
  border-width: 5px 2.5px;
  /* top/bottom right/left */
}

.assessments-header {
  justify-self: start;
}

.feature-list {
  background-color: #2c3e50;
  /* Dark blue shade */
  border-radius: 15px;
  /* Curved border */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  /* Shadow for the ul */
  padding: 20px;
  /* Centering the ul if it's within a container */
}

.course-content {

  background-color: #4b6986;
  /* Dark blue shade */
  border-radius: 15px;
  /* Curved border */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  /* Shadow for the ul */
  padding: 20px;
  /* Centering the ul if it's within a container */
  overflow-x: auto;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  
}

.course-content p {
  color:bisque;
  margin-bottom: 1.5rem;

}

.course-content ul{
  padding-left: 20px;
  list-style: disc;
}

/* Course Syllabus */
.course-syllabus {
  color: bisque;  /* Setting the text color to bisque */
  background-color: #4b6986;  /* Deep blue background for the whole div */
  padding: 20px;  /* Padding around the content for better spacing */
  border-radius: 8px;  /* Rounded corners for a softer look */
  font-family: 'Arial', sans-serif;  /* Modern and readable font */
  box-shadow: 0px 4px 8px rgba(0,0,0,0.1);  /* Subtle shadow for depth */
}

.course-syllabus h2 {
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);  /* Subtle text shadow for the title */
  color: #fff2ad;  /* Gold color for headings to make them stand out */
  margin-bottom: 16px;  /* Extra space below the heading */
}

.course-syllabus p{
  color: #fff2ad;  /* Gold color for headings to make them stand out */
}

.course-syllabus ol {
  padding-left: 20px;  /* Indenting the ordered list for clarity */
}

.course-syllabus li {
  margin-bottom: 10px;  /* Keeping your spacing between list items */
  line-height: 1.6;  /* Improved line height for better readability */
}

.course-syllabus ul {
  padding-left: 20px;  /* Indenting unordered lists */
}

.course-syllabus li ul {
  margin-top: 8px;  /* Space above the nested lists */
  list-style-type: square;  /* Different list item marker for sub-items */
}

/* Extra styling for nested list items to differentiate from primary items */
.course-syllabus li ul li {
  font-size: 0.95em;  /* Slightly smaller font size for sub-items */
  color: lightgray;  /* Lighter color for less emphasis */
}


/* End of Course Syllabus */

.assessments-top-row .feature-list{
  overflow-x: auto;
  margin-right: none;
}

.feature-list li {
  color: white;
  font-size: 18px;
  /* Adjust based on your design */
  margin-bottom: 15px;
  /* Spacing between list items */
  display: flex;
  align-items: center;
  line-height: 1.2;
}

.feature-list li:last-child {
  margin-bottom: 0;
  /* Removing the margin for the last item */
}

.feature-list li>svg {
  margin-right: none;
  font-size: 24px;
}

.beta-label {
  background-color: purple;
  color: white;
  padding: 2px 5px;
  font-size: 0.8em;
  border-radius: 3px;
  margin-left: 5px;
}

@media (max-width: 600px) {
  .assessments-top-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .learning-home-sub-menu-action-container {
    text-align: left;
    margin-bottom: 1rem;
  }

  .learning-path-button .assessment-button .learning-component-button{
    max-width: 400px;
  }
}