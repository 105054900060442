.top-menu-bar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 10%;
  background-color: #4a4e4d;
  padding: 1rem;
  color: white;
  gap: 1rem; /* Add a gap between items for better visual separation */
}

.top-menu-bar-item {
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.top-menu-bar-button {
  background-color: transparent;
  border: 2px solid white;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
}

.top-menu-bar-button a{
  color: #fff; /* Set the color to white, or any other color you prefer */
  text-decoration: none; /* Remove the underline from the link */
}

.top-menu-bar-label {
  margin-left: 2.5px;
}

.top-menu-bar-button:hover {
  background-color: #f5da97;
  color: #4a4e4d;
}

.countdown-timer-text-interview{
    font-weight: bold;
    color: #f6cd61;
    font-size: 20px;
}

.premium-label{
  margin-left: 5px;
  background-color: white;
  border-radius: 3px;
  padding: 5px 3px;
  color: #4a4e4d;
}