.disabled-option {
  color: lightgray;
  cursor: not-allowed;
  pointer-events: none;
}

.not-started-container {
  height: 90%;
}

.challenge-box-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
}

.content-container {
  height: 650px;
  display: flex;
  margin-bottom: 1rem;
  overflow-y: auto;
  overflow-x: auto;
}

.challenge-navbar {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 3rem;
  overflow-x: auto;
  overflow-y: hidden;
}

.challenge-navbar-item {
  position: relative;
  left: 20%;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
}

.challenge-navbar-item.active-option {
  background-image: linear-gradient(135deg, #83a4d4, #91e8ec);
  color: white;
}

.challenge-navbar-item:hover {
  background-color: #e0e0e0;
}

.hamburger-icon {
  display: flex;
  align-items: center;
}

.navbar-assessment-title {
  margin-bottom: 0;
  flex-grow: 1;
  font-weight: 700;
  font-size: 1em;
  color: #333333;
}

.challenge-category {
  display: grid;
  align-items: flex-end;
}

.buttons-container {
  margin-top: 10px;
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
}

.buttons-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.interactive-button {
  white-space: nowrap;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

@media (max-width: 1149px) {
  
  .challenge-navbar{
    padding: 27px;
  }
}

@media (max-width: 1280px) {
  .challenge-navbar{
    padding: 27px;
  }
  .content-container{
    height: auto;
  }
}

@media (max-width: 768px) {
  .buttons-row {
    flex-direction: column;
  }
  .interactive-button {
    margin-bottom: 1rem !important;
  }
}

@media (max-width: 623px) {

  .challenge-box-container {
    width: 89vw;
    font-size: small;
    overflow-y: auto;
    white-space: normal;
  }

  .content-container {
    height: auto;
    display: flex;
    margin-bottom: 1rem;
    font-size: small;
    overflow-y: auto;
    white-space: normal;
  }

  .challenge-navbar {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: normal;
  }
}