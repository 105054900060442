.solution-content-container {
    margin: 2rem;
    height: 100%;
}

h1 {
    font-family: 'Arial Rounded MT', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #333333;
}

h2 {
    font-family: 'Arial Rounded MT', sans-serif;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

p {
    font-size: 1.1rem;
    line-height: 1.5;
    color: #555;
    margin-bottom: 1.5rem;
}

pre {
    line-height: 1.2;
    font-family: monospace;
    font-size: smaller;
}

.h2-acceptable-answers {
    text-align: center;
    font-size: 2rem;
    font-family: Arial Rounded MT, sans-serif;
    font-weight: 700;
    margin-bottom: 1rem;
}

.solution-content-container > .interview-solutions-container > .challenges-and-solutions-container > .solutions-container {
    width: 100%;
}

.solution-content-container > .interview-solutions-container > .challenges-and-solutions-container > .solutions-container > .challenge-container > pre {
    overflow-x: auto;
    overflow-y: hidden;
}

.acceptable-answers-item {
    list-style-type: none;
    font-weight: bold;
}

/* Media queries for screen sizes */
@media (max-width: 1200px) {
    .solution-content-container > .interview-solutions-container > .challenges-and-solutions-container > .solutions-container {
        width: 100%;
    }
}

@media (max-width: 623px) {
    h1 {
        font-size: 1.5em;
        margin-bottom: 1rem;
    }

    h2 {
        font-size: 1.5em;
    }

    p {
        font-size: 1em;
        margin-bottom: 1.5rem;
    }

    .h2-acceptable-answers {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    .problem-statement h2 {
        text-align: left;
        font-size: 1.2em;
    }
}