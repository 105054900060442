.output-tabbed-view-container {
  background-color: #f2f2f2;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  flex-grow: 1;
  margin: auto;
  display: flex;
  border-radius: 10px;
  float: left;
}

.output-tabbed-view-header {
  display: flex;
  margin-bottom: 20px;
}

.output-tabbed-view-header button {
  background-color: #ddd;
  border: 1px solid #ddd;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  cursor: pointer;
}

.output-tabbed-view-header .selected-tab, .output-tabbed-view-header .selectable-tab:hover {
  opacity: 1;
}

.output-tabbed-view-header .selectable-tab{
  opacity: 0.6 ;
}
.output-tabbed-view-header .std-out-tab{
  background-color: #0097a9;
}

.output-tabbed-view-header .std-err-tab{
  background-color: #e36c5c;
}

.output-tabbed-view-header .execution-metrics-tab{
  background-color:#9191e1;
}

.output-tabbed-view-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  overflow-y: scroll;
  max-height: 50%;
}