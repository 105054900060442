.learning-path-generator {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px; /* Increased the width a bit */
  margin: auto;
  padding: 20px;
  border: 1px solid #e0e0e0; /* Optional: added a light border for better containment */
  border-radius: 8px; /* Optional: rounded corners */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: light shadow for depth */
}

.quick-actions {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px; /* or adjust as you like */
}

.guidance {
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.topic {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

/* Styling for the quick action buttons */
button {
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 5px; /* Spacing between buttons */
}

button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.start-challenge-button {
  min-width: 155px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Mobile responsiveness */
@media screen and (max-width: 600px) {
  .learning-path-generator {
    padding: 10px;
  }

  .topic {
    flex-direction: column;
    align-items: flex-start;
  }
  .start-challenge-button {
     max-width: 155px; 
    
  }
  .unit-top-row{
    align-items: center;
  }
}


.bulk-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: scroll;
}

.concept-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: scroll;
}

.bulk-actions-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: scroll;
}

.concept-table .MuiTableBody-root .MuiTableRow-root:nth-child(odd) {
  background-color: #ffffff; /* White for odd rows */
}

.concept-table .MuiTableBody-root .MuiTableRow-root:nth-child(even) {
  background-color: #f7f7f7; /* Light gray for even rows */
}

.MuiTableHead-root.css-15wwp11-MuiTableHead-root {
  background-color: #f7f7f7;
}

@media (max-width: 420px){
  .bulk-actions-row{
    display: flex;
    flex-direction: column;
  }
  
}
