.coding-sandbox-container {
    width: 70%;
    margin: 20px auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.4s ease, border-color 0.4s ease;
    flex: 1;
    overflow: auto;
}

.coding-sandbox-container:hover {
    border-color: #bdbdbd;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .coding-sandbox-container {
        width: 95%;
    }
}
