/* Careers.css */

/* Define CSS variables for reusable values */
:root {
  --primary-bg-color: #64c8cd;
  --secondary-bg-color: #ab7ae2;
  --primary-text-color: #ffeeb9;
  --secondary-text-color: white;
  --button-bg-color: #ffeeb9;
  --button-hover-bg-color: #fcdb77;
  --hiring-paused-color: #ffc2b9;
  --border-color: #ccc;
  --font-family-heading: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

/* Container for the entire careers page */
.careers-container {
  padding: 20px;
}

/* Title indicating the number of open positions */
.careers-container h1 {
  text-align: center;
  color: charcoal;
  margin-bottom: 30px;
}

/* Grid layout for job postings */
.careers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  justify-items: center;
}

/* For screens wider than 900px, limit to 3 columns */
@media screen and (min-width: 900px) {
  .careers-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* For screens between 600px and 899px, use 2 columns */
@media screen and (min-width: 600px) and (max-width: 899px) {
  .careers-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* For screens less than 600px, use 1 column */
@media screen and (max-width: 599px) {
  .careers-grid {
    grid-template-columns: 1fr;
  }

  /* Other adjustments for small screens */
  .careers {
    width: 100%;
  }

  .careers__description {
    padding: 10px;
  }

  .careers h3 {
    font-size: 18px;
  }

  .careers p {
    font-size: 16px;
  }

  .careers__applyButton {
    width: 100%;
    text-align: center;
  }
}

/* Individual job posting card */
.careers {
  background-color: var(--primary-bg-color);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  box-sizing: border-box;
}

.careers__title {
  margin: 0;
  font-size: 24px;
  color: var(--primary-text-color);
  text-align: center;
}

.careers__description {
  font-size: 16px;
  color: var(--secondary-text-color);
  text-align: left;
  padding: 20px;
}

.careers p {
  color: var(--secondary-text-color);
  font-size: 18px;
}

.careers li {
  margin-bottom: 5px;
}

.careers h3 {
  margin-top: 5px;
  font-weight: bold;
  color: var(--primary-text-color);
  font-family: var(--font-family-heading);
  font-size: 20px;
}

.careers i {
  color: var(--primary-text-color);
  text-align: center;
  display: block;
  margin-top: 10px;
}

/* Indent nested list items */
.careers li li {
  margin-left: 15px; /* Adjust the value to your preferred indentation */
}


.careers__applyButton {
  margin: 20px 0;
  padding: 10px 20px;
  font-size: 16px;
  color: black;
  background-color: var(--button-bg-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.careers__applyButton:hover {
  background-color: var(--button-hover-bg-color);
}

.careers__status {
  color: var(--hiring-paused-color);
  font-size: 18px;
  margin-top: 10px;
  text-align: center;
}

.careers__consent {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-text-color);
  margin-top: 10px;
}

.careers__consent input {
  margin-right: 5px;
}

.careers__hiringStatus {
  padding: 10px;
  background-color: var(--secondary-bg-color);
  color: var(--secondary-text-color);
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 100%;
}

a.questionnaire {
  text-decoration: underline;
  color: blue;
}