@keyframes bannerColorChange {
    0% {
        color: black;
    }

    50% {
        color: rgb(4, 90, 139);
    }

    100% {
        color: black;
    }
}

.join-workshop-button {
    animation: bannerColorChange 5s infinite;
    width: 100%;

}

.join-workshop-button:hover {
    opacity: 90%;
}