/* create a grid with a single column but multiple rows allowed, column should take 90% of width */
.humanInterviewCoach {
  display: grid;
  grid-template-columns: 100%;
  /* this means that the column will take 90% of the width */
  grid-template-rows: repeat(auto-fill);
  /* this means that the rows will be created automatically */
  /* grid-gap: 10px; */
  /* margin: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    */
  background-color: white;
  justify-content: center;
  align-items: center;
}

.humanInterviewCoach h1 {
  font-family: "Arial Rounded MT", sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
}

.humanInterviewCoach h2 {
  font-family: "Arial Rounded MT", sans-serif;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
}

.humanInterviewCoach h3 {
  font-family: "Arial Rounded MT", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
}

.humanInterviewCoach ul,
.humanInterviewCoach p {
  color: white;
}

.landing-page-row {
  border: 1px solid #ccc;
  /* border-radius: 5px; */
  padding: 15px;
  width: 100%;
}

.main-message-container,
.features-container,
.booking-and-payment-container {
  background-image: linear-gradient(135deg, #83a4d4, #b6fbff);
}

.main-message-button-container .primary-call-to-action-button {
  background-image: linear-gradient(189deg, #ffff99, #fdd835);
  color: black;
}

.main-message-button-container .silver-button {
  background-image: linear-gradient(
    135deg,
    #bebcbc 1%,
    #e0e0e0 10%,
    #e0e0e0 80%,
    #bebcbc 100%
  );
  color: black;
  border: 1px solid #c0c0c0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.main-message-container h2 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  line-height: 1.3;
}
.main-message-container h1 {
  line-height: 1.3;
  margin-bottom: 10px;
}

.main-message-container .highlight-text {
  color: #f5f5f5;
  padding: 5px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
}

.social-proof-container .highlight-text {
  color: black;
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(198, 227, 250, 0.2);
  text-align: center;
}

.main-message-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.main-message-button-container .secondary-call-to-action-button {
  margin-left: 10px;
}

.pain-points-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.pain-points-container-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c6e3fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16);
  padding: 10px;
  font-style: italic;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  line-height: 1.2;
}

.social-proof-container,
.contact-section-container {
  background-color: white;
}

.social-proof-container .company-logos-container,
.social-proof-container .generic-tech-logos-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-proof-container .generic-tech-logos-container img {
  max-width: 600px;
}
.social-proof-container .company-logos-container img {
  max-height: 70px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.alternate-theme-container {
  color: black;
  border: none;
}

.alternate-theme-container p,
.alternate-theme-container ul,
.alternate-theme-container li {
  color: black;
}

.how-it-works-container {
  font-family: "Comic Sans MS", "Comic Sans", "Nunito", "Verdana", cursive,
    sans-serif;
}

.how-it-works-container ul {
  margin-left: 50px;
}

.contact-section-container p {
  color: black;
}

.contact-section-child-container {
  display: flex;
  align-items: center; /* This ensures vertical alignment if the items have different heights */
  justify-content: space-between; /* This spreads out the items across the available space */
}

.contact-section-container .contact-details,
.contact-section-container .contact-section-image-container {
  padding: 10px;
  flex: 1; /* This allows both children to grow and take up equal space */
}

.contact-details a {
  text-decoration: none;
  color: inherit;
}

.contact-details a:hover {
  cursor: pointer;
}

.contact-section-container .contact-section-image-container img {
  border: solid 1px #ccc;
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.685);
}

/* Page specific FAQ CSS customization */
.contact-section-container.landing-page-row {
  padding: 20px;
}

.contact-section-right-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 2rem;
}

.contact-section-child-container {
  height: 100%;
  padding-top: 40px;
  padding-bottom: 10px;
}

.contact-section-right-container .faq-container {
  margin: 0;
}

.contact-section-right-container .faq-item {
  background-color: #fffb8f;
}

.contact-section-right-container .faq-item .question h3,
.contact-section-right-container .faq-item.open .answer p,
.contact-section-right-container .faq-item.open .answer ul {
  color: #333;
  font-family: "Comic Sans MS", "Comic Sans", "Nunito", "Verdana", cursive,
    sans-serif;
}

.contact-section-right-container .pagination-controls button {
  background-color: #fffb8f;
  color: #333;
  font-family: "Comic Sans MS", "Comic Sans", "Nunito", "Verdana", cursive,
    sans-serif;
  font-weight: bold;
}

.contact-section-right-container .FAQ {
  min-width: 675px;
}

.contact-section-container .contact-details {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.contact-mock-examples {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-details .book-button {
  padding: 25px 30px;
  border-radius: 2rem;
  font-size: larger;
  margin: 0;
  border: 2px solid black;
}

.how-it-works-container .session-text-container {
  display: flex;
  justify-content: space-around;
}

.how-it-works-container .session-text-container .session-text-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.session-text-container .session-text-right .book-button {
  padding: 30px 35px;
  border: 2px solid black;
  font-size: 1.4rem;
  border-radius: 20px;

  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  text-shadow: 1px 1px 2px black;
}

/* Thumbnail background overlay styling */
.button-with-thumbnail {
  background-image: var(--thumbnail-url, none);
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.685);
}

.button-with-thumbnail::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: inherit;
  z-index: 1;
  transition: background-color 0.3s ease;
}

.button-with-thumbnail span {
  position: relative;
  z-index: 2;
}

.button-with-thumbnail:hover::before {
  background-color: rgba(0, 0, 0, 0.2);
}

.button-with-thumbnail:hover span {
  opacity: 0.2;
}

@media (max-width: 650px) {
  .how-it-works-container .session-text-container {
    flex-direction: column;
    width: auto;
  }
}

/* Media queries adjust to scroll length at Ipad Pro's Width */
@media (max-width: 853px) {
  .social-proof-container {
    overflow-x: scroll;
  }

  .social-proof-container .generic-tech-logos-container img {
    max-width: 400px;
  }
  .social-proof-container .company-logos-container {
    max-width: 600px;
    flex-wrap: wrap;
  }

  .social-proof-container .company-logos-container img {
    max-height: 50px;
  }

  .contact-section-child-container {
    flex-direction: column-reverse;
    gap: 50px;
  }

  .contact-section-container .contact-details {
    align-self: center;
  }
  
  .contact-section-container .contact-section-image-container img, 
  .contact-section-container .contact-section-right-container  {
    width: 100%;
  }

  .contact-section-container .contact-section-right-container .FAQ {
    min-width: 300px;
  }

  .contact-section-container .contact-section-right-container .FAQ h2 {
    font-size: 1.25rem;
    top: -40px;
    left: 0px;
  }
}


/* Need to adjust for 1400px - 1024px width range */
@media (max-width: 1400px) {
  .contact-section-right-container .FAQ {
    min-width: 300px;
  }

  .contact-section-right-container .FAQ h2 {
    font-size: 1.25rem;
  }

  .contact-section-right-container, .contact-section-image-container {
    padding: 10px;
  }

  .contact-details button {
    padding: 15px 20px;
  }

  .contact-mock-examples h3 {
    font-size: 1.2rem;
  }
}
