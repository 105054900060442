.modal-title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 2rem;
  font-family: Arial Rounded MT, sans-serif;
  font-weight: 700;
}

.interview-selection-list {
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.interview-selection-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.5em;
  width: 60%;
  min-width: 700px;
  transition: background-color 0.3s, box-shadow 0.2s;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.interview-selection-item:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.item-detail {
  margin: 0 10px;
  text-align: center;
}


.my-datagrid-class .MuiDataGrid-row:nth-child(odd) {
  background-color: #f8f8f8;
}


.my-datagrid-class .MuiDataGrid-columnHeader {
  background-color: white;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

/* mobile */
@media (max-width: 600px) {/*mobile*/
  .mobile-note {
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
    max-width: 350px;
    color: white;
    background-color: #455a64;
    border-radius: 5px;
    padding: 8px;
    text-align: center;
  }

  .my-datagrid-class .MuiDataGrid-row:nth-child(odd) {
    background-color: white;
  }
}
@media (min-width: 600px) {
  .mobile-note {
    display: none;
  }
}