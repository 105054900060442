.courses-menu {
  margin-top: 10px;
  margin-bottom: 10px;
}

.courses-title {
  text-align: left;
  margin-bottom: 10px;
  position: relative;
  padding: 10px 20px;
  text-align: left;
  color: #ffebcd;
  background-color: #722b23;
  border-radius: 10px;
}

.course-header {
  justify-self: start;
}

.courses-text {
  color: black;
  text-shadow: 1px 1px 2px white;
  margin-top: 1rem;
  display: inline-block;
  flex-grow: 0;
  position: relative;
  padding: 0px 30px;
  text-align: left;
  background-color: white;
  border-width: 5px 2.5px 5px 2.5px;
  border-style: solid;
}

.menu-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
  margin: 0 20px; /* Equal margin from both sides */
}

.course-card {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 160px;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  position: relative;

  /* If you want a default background color behind the image: */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* Optionally keep a default color for partial loads:
  background-color: white; 
  */
}

/* 
  Fallback for no-thumbnail: 
  ensures the card is white if there's no thumbnail 
*/
.course-card.no-thumbnail {
  background-color: white;
}

.course-card-title {
  padding: 0.5px;
  text-align: center;
}

/* White text if there's a background image, you can keep or modify */
.course-card h5 {
  font-size: 1.3rem;
  font-weight: bold;
  color: white; 
}

/* If there's no thumbnail, the title color is set */
h5.course-card-title.no-thumbnail-title {
  color: #1976d2;
  font-weight: unset;
  text-shadow: none;
}

/* 
  The container for the logo & title, 
  ensuring they're centered
*/
.course-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-top: 10px; /* optional spacing above */
}

/* If a logo is present, how to style it */
.course-logo {
  max-width: 150px;
  height: auto;
  margin-bottom: 1px; /* spacing between logo and title */
}

.course-progress {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.beta-ribbon-container {
  opacity: 80%;
  position: absolute;
  top: -3px;
  left: -12.5px;
  z-index: 2;
}

/* Adjust layout for small screens */
@media screen and (max-width: 768px) {
  .menu-cards-container {
    grid-template-columns: 1fr; /* Stack cards vertically */
    margin: 10px 0; /* Adjust side margins for smaller screens */
  }

  .courses-title {
    font-size: medium;
  }
}
