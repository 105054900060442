.cv-service-container {
    display: grid;
    grid-template-columns: 3fr 7fr;
    gap: 20px;
    margin: 0 15px;
}

.explanations-side-bar {
    grid-row: 1;
    grid-column: 1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 20px;
    min-height: 600px;
    background-color: #2b70c9;
}

.explanations-side-bar h2{
    color: white;
    padding: 10px;
    background-color: #2867b9;
    border-radius: 10px;
}

.explanations-side-bar p{
    color: #eaedf0fb;
}

.explanations-side-bar p.highlight{
    color: white;
    font-weight: bold;
    font-size: larger;
    padding: 10px;
    background-color: #2867b9;
    border-radius: 10px;
    text-align: center;
    width:fit-content;
}

.cv-services {
    grid-row: 1;
    grid-column: 2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 20px;
    display: grid;
    gap: 20px;
}


@media (max-width: 1100px) {
    .cv-service-container {
      grid-template-columns: 100%; /* only one column */
    }

    .explanations-side-bar {
        grid-column: 1;
        grid-row: 1;
        width: 100%;
    }

    .cv-services {
        grid-column: 1;
        grid-row: 2;
        width: 100%;
    }
}
  
