/* Global Styling */
body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Page Layout */
.jobs-page-container {
  display: flex;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  animation: fadeIn 1s ease-in-out;
}

.jobs-page-content {
  flex: 3;
  background-color: #fff;
  border: 1px solid #ddd; /* Add border similar to the sidebar */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 20px;
  animation: fadeIn 1s ease-in-out;
}

/* Sidebar */
.jobs-sidebar {
  flex: 1;
  background: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Title Styling */
.jobs-title {
  font-size: 2.8rem;
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  letter-spacing: 2px;
  animation: bounceIn 1.5s ease;
}

/* Jobs Section */
.jobs-section {
  background: linear-gradient(135deg, #f9f9f9, #eef2ff);
  padding: 25px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.jobs-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Links */
.job-link {
  font-size: 1.1rem;
  color: #007bff;
  text-decoration: none;
  display: block;
  margin-top: 8px;
  transition: color 0.3s ease, transform 0.2s ease;
}

.job-link:hover {
  color: #003d80;
  transform: translateX(5px);
}

/* Responsive Layout */
@media (max-width: 768px) {
  .jobs-page-container {
    flex-direction: column;
    padding: 15px;
  }

  .jobs-sidebar {
    order: -1;
  }
}


/* CV Review Section */
.cv-review {
  text-align: center;
  padding: 20px;
  background: linear-gradient(135deg, #f0f8ff, #dbeafe);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cv-review h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.cv-review p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.cv-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.cv-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .jobs-page-container {
    flex-direction: column;
    padding: 15px;
  }

  .jobs-sidebar {
    order: -1; /* Sidebar comes first on smaller screens */
  }

  .jobs-title {
    font-size: 2.2rem;
  }

  .section-title {
    font-size: 1.4rem;
  }

  .job-link {
    font-size: 1rem;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  60% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
