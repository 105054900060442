.link-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
}

.my-button, .give-feedback-button {
    border-radius: 20px;
    padding: 10px 20px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.3s ease;
    cursor: pointer;
    font-size: 14px;
}

.give-feedback-button {
    border: none;
    background-color: #28a5b3;
    color: #fff; /* If you want the text color to be white */
}

.give-feedback-button:hover {
    background-color: #1c7a85;
}
.interview-solutions-container {
    width: 100%;
    margin: auto;
    font-family: Arial, sans-serif;
}

.challenges-and-solutions-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.challenges-container {
    width: 15%;
}

.solutions-container {
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: 85%;
    padding-left: 20px;
}

.interview-solutions-container .toggle-button {
    display: block;
    border: 1px solid black;
    border-radius: 16px;
    padding: 10px 20px;
    background: linear-gradient(to bottom right, #2b70c9, #2b6fc9f5, #2b6fc9c1);
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;
    margin-bottom: 0.25rem;
}

.interview-solutions-container .toggle-button:hover {
    background: #1c6ea4;
    color: #fff;
}

.challenge-container {
    background-color: black;
    color: white;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px;
}

.challenge-container > p{
    color: white;
}

.challenge-container > pre{
    background-color: white;
    color: black;
    border-radius: 10px;
    padding: 1rem;
}

.solution-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
    margin-top: 20px;
}

.solution-button {
    border: 2px solid;
    border-radius: 20px;
    background-color: #ff4b4b;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.solution-button:hover {
    background: #d60d0dc6;
}

.solution-button.selected {
    background: linear-gradient(to bottom right, #2b70c9, #2b6fc9f5, #2b6fc9c1);
}

.solution {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    font-size: 14px;
    color: #333;
    max-width: 100%;
    overflow-x: auto;
}

.pre-tag{
    max-width: 700px;
    overflow: auto;
}

.explanation-button {
    border: 1px solid rgb(225, 225, 225);
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-right: 0.5em;
    margin-top: 0.5em;
}

.explanation-button-quick {
    background-color: #1976d2
}
.explanation-button-quick:hover {
    background-color: #0b4d90
}


.explanation-button-thought-process {
    background-color: #28a5b3;
}
.explanation-button-thought-process:hover {
    background-color: #1c7a85;
}

.explanation-container {
    background-color: black;
    border-radius: 10px;
    padding: 30px;
    margin-top: 20px;
    font-size: 14px;
    color: white;
    max-width: 100%;
    overflow-x: auto;
}
.explanation-container p{
    color: white;
}
.explanation-container code{
    color: rgb(187, 22, 187);
}
@media (max-width: 1200px) {
    .challenges-and-solutions-container {
        flex-direction: column;
    }

    .challenges-container {
        width: 100%;
    }

    .solutions-container {
        width: 100%;
    }
}