.mcq-box {
    height: 100%;
    width:100%;
    display: flex;
    flex-direction: column;
}

.mcq-navbar {
    background-color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 50px;
    margin-bottom: 10px;
    width:100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.mcq-timer {
    margin-right: 1rem;
}

.mcq-timer.extra-margin-right {
    margin-right: 4rem;
}

.mcq-options-container {
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
}

.mcq-options-container .MuiFormControlLabel-root {
    margin-top: 1rem;
    margin-bottom: 1rem;
}


@media (max-width: 589px) {
    .mcq-options-container {
      width: 82vw;
    }
    .code-editor-navbar {
      width: 89vw;
    }
    .mcq-navbar{
        justify-content: center;
    }
  }