.media-iframe-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.media-iframe-navbar {
  height: 50px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.media-iframe {
  border: none;
  flex-grow: 1;
}

@media screen and (max-width: 600px) {
  .media-iframe-container {
    width: 89.8vw;
  }
}