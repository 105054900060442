.courses-container2{
  display:flex;
  flex-direction:row;
  width: 100%;
}

.assessment-init-menu-grid{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    width: 90%;
    height: 85% !important;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
  }

.assessment-init-menu-row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.diagnostic-assessment-header{
  font-size: 2rem;
  font-family: Arial Rounded MT, sans-serif;
  font-weight: 700;
  margin-bottom: 0.8rem 
}

.assessment-init-menu-row label {
  margin-bottom: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Arial Rounded MT", sans-serif;
}

.assessment-init-menu-row select {
  font-size: 1.2rem;
  padding: 10px;
  border-radius: 10px;
  color: black;
  border: none;
  min-width: 200px;
  margin-left: 5px;
}

.assessment-guidance-container .assessment-guidance {
  background-color: #2b70c9;
  border-radius: 10px;
  padding: 20px;
  color: white;
}

.select-style {
  font-weight: normal; 
  font-family: Arial, sans-serif; 
}

.assessment-guidance p{
  margin-bottom: 20px;
}

.assessment-guidance-container ul{
  margin-top: 5px; 
  margin-bottom: 5px;
}
.assessment-guidance-container .intro {
  font-size: 18px;
  color: #dde6f1;
}
.assessment-guidance-container li {
  font-size: 22px;
  list-style: none;
}

.initial-assessment-box{
  height: auto;
  overflow: scroll;
}

.boxes-container2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  margin-bottom: 1rem;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 2rem;
  overflow: auto;
  width: auto;
}

.boxes-wrapper {
  display: flex;
  flex-direction: row; 
  gap: 10px; 
  width: 100%;
  padding: 8px 0px;
  overflow-x: auto;
  overflow-y: hidden;
}
 
.inter-menu{
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex: 1 1 0%;
  margin: 0px 5px;
  color: white;
  background-color: rgb(145, 145, 225);
}

.all-boxes{
  display: flex;
  flex-direction: row;
}

@media (max-width: 1200px) {
  .courses-container2{
    flex-direction: column ;
  }
  .boxes-wrapper{
    flex-direction: column;
    width:auto;
  }
  .boxes-container2{
    flex-direction: column;
    width: auto;
  }
}
