nav ul li a:hover {
  color: white;
}

.navbar {
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-x: visible;
  overflow-y: visible;
  background-image: linear-gradient(135deg, #83a4d4, #b6fbff);
  margin: 0;
  position: relative;

  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0; /* This defines the point at which the navbar will stick to the top */
  z-index: 90;
}

.navbar ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow-x: auto; 
  -webkit-overflow-scrolling: touch;
  height: 51px;
}

.navbar li{
  flex: 0 0 auto; 
  min-width: 1.5vw; 
}

.navbar .actionBtn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
  bottom: -50%;
  padding: 18px 50px;
  background: rgba(179, 95, 40, 0.7);
  /* z-index: 2; */
  cursor: pointer;

  font-size: 30px;
  font-weight: 500;
  color: #f3ecd8;

  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@keyframes goldenBorder {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animatedBorder {
  animation: goldenBorder 8s infinite;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.animatedBorder:hover {
  background-color: #f4d17a; /* or any other highlight color */
  opacity: 1 !important;
}

.navbar li.button,
.navbar a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  height: 45px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0.5px;
  padding-right: 0.5px;
  color: #4a4e4d;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.2rem;
  font-family: "Arial Rounded MT", sans-serif;

  transition: color 0.2s ease-in-out;
  /* border: 2px solid transparent; */
  cursor: pointer;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar .beta-label {
  background-color: #e67321;
  color: white;
  padding: 2px 5px;
  font-size: 0.8em;
  border-radius: 3px;
  margin-left: 5px;
}

.site-logo-container img {
  background-color: white;
  border-radius: 40px;
  width: 50px;
  min-width: 50px;
  height: auto;
}

.icons {
  /* display: grid; */
  display: none;
  grid-column: span 1;
  grid-column-start: -1;
  grid-template-columns: repeat(2, 32px);
  grid-gap: 20px;
  justify-content: flex-end;
  align-items: center;
  margin-right: 30px;
}

.icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: stretch;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.disabled-icon {
  pointer-events: none;
  opacity: 0.2;
}

.logIn {
  background: rgba(69, 150, 232, 0.5);
  padding: 20px 10px;
  border: 1px solid black;
}

/* Mobile View */

.mobileNav {
  display: none;
  overflow-x: visible;
  background-image: linear-gradient(135deg, #83a4d4, #b6fbff);
}

.mobileNav li.button,
.mobileNav a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  height: 25px;
  padding-top: 5px 3px;
  color: #4a4e4d;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.1rem;
  font-family: "Arial MT2";
  transition: color 0.2s ease-in-out;
  /* border: 3px solid transparent; */
  cursor: pointer;
}

.hamburger-menu {
  position: relative;
  display: inline-block;
}

.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  width: 25px;
  margin-left: 10px;
  cursor: pointer;
}

.hamburger-icon span {
  width: 100%;
  height: 2px;
  background-color: #4a4e4d;
  transition: all 0.1s ease-in-out;
}

.hamburger-links {
  position: absolute;
  top: 100%;
  right: 0;
  background-image: linear-gradient(135deg, #83a4d4, #b6fbff);
  width: 250px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
}

.hamburger-links.open {
  max-height: 300px;
  font-weight: 600;
  font-size: 1.5rem;
  padding: 10px;
  border-radius: 1.5rem;
  z-index: 90;
}

.hamburger-icon.open span:first-child {
  transform: rotate(45deg) translate(10px, 5px);
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open span:last-child {
  transform: rotate(-45deg) translate(10px, -5px);
}

.credentials {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
}

/* Home class with Web */

.home {
  background-color: rgba(145, 163, 143);
  background-image: none;
}

.navbar.home li.button,
.navbar.home a {
  color: #f3ecd8;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

nav.home ul li a:hover {
  color: black;
}

/* Home Class with Mobile */

.mobileNav.home li.button,
.mobileNav.home a {
  color: #f3ecd8;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.home .logIn {
  background-color: #755248;
}

.home .hamburger-icon span {
  background-color: #f3ecd8;
}

.home .hamburger-links {
  background-color: #91a38f;
  background-image: none;
}


@media screen and (max-width: 768px) {
  .navbar {
    display: none;
  }
  .mobileNav {
    display: flex;
    padding: 30px 15px;
    margin-bottom: 0;
  }

  .mobileNav ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    
  }

  .mobileNav li.signUp a,
  .mobileNav li.signIn a,
  .mobileNav li.signOut a {
    font-size: 1.25rem;
    font-weight: 500;
  }

  .site-logo-container img {
    background-color: white;
    
  }
}



/* Dropdown menu */
.dropdown-menu {
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 0;
  min-width: 150px;
}


.dropdown-menu .button:hover {
  background-color: #eaeaea;
  color: #007bff;
}


.mobile-title {
  text-align: center;
  border-radius: 10px;
  height: 45px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0.5px;
  padding-right: 0.5px;
  color: #4a4e4d;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.2rem;
  font-family: "Arial Rounded MT", sans-serif;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}