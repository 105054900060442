.blog-post-tile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 200px;
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
  color: white;
  font-size: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  background-size: cover;
  background-position: center;
  background-color: #3498db;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-post-tile:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}


.blog-post-tile-title {
  position: relative;
  z-index: 2;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
  transition: color 0.3s ease;
  flex: 2;
  margin-right: 15px;
}

.blog-post-title {
  color: white;
}

.blog-post-title button {
  position: relative;
  z-index: 2;
  background-color: #ffffff;
  color: #3498db;
  border: 2px solid #3498db;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
  flex-shrink: 0;
}


@media (max-width: 768px) {
  .blog-post-title {
    height: auto;
    padding: 15px;
  }

  .blog-post-title {
    font-size: 20px;
  }

  .blog-post-title button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .blog-post-title {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .blog-post-title {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .blog-post-title button {
    padding: 6px 12px;
    font-size: 12px;
  }
}

