.dashboard-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid #FFD99F;
    background-color: #FFD99F;
    margin: 0.625rem;
    text-align: center;
    width: 10rem;
    height: 3rem;
    font-weight: bold;
    font-size: large;
    border-radius: 10px;
  }
  
  .page-header {
    margin: 0.25rem;
  }
  
  .progress-summary-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, #000691, #1D9100);
    margin: 0.625rem;
    padding: 0.125rem;
    border-radius: 22px;
  }
  
  .progress-summary-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background-color: white;
    border-radius: 20px;
    padding: 1.25rem;
    text-align: center;

    justify-content: space-between;
  }
  
  .progress-info, .current-journey-container, .continue-journey-container {
    /* flex: 1; */
    min-width: 200px;
    margin: 0.5rem;
  }

  .current-journey-container {
    display: flex;
    flex-direction: column;
  }
  
  .progress-title {
    font-weight: bold;
    font-size: 25px;
    padding-bottom: 0.5rem;
  }
  
  .progress-subtitle {
    margin: 0;
    font-size: 1.3rem;
  }
  
  .experience-points {
    font-size: 1.3rem;
  }
  
  .current-journey-title {
    text-align: center;
  }
  
  .current-journey-card {
    width: fit-content;
    cursor: pointer;
  }
  
  .current-journey-details {
    width: 280px;
    border-radius: 8px;
    padding: 1rem;
  }
  
  .current-journey-text {
    text-align: center;
  }
  
  .current-journey-title-text {
    font-weight: bold;
    font-size: 1.3rem;
    color: white;
    padding-bottom: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .current-journey-status {
    font-size: 0.9rem;
    color: white;
    padding-bottom: 0.125rem;
  }
  
  .current-journey-progress {
    font-size: 0.75rem;
    color: white;
  }
  
  .progress-button {
    background-color: #ffca7a;
    border: solid transparent;
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: large;
    cursor: pointer;
    margin-left: 1.875rem;
    padding: 0.375rem 0.75rem;
    border-radius: 10px;
    transition: background-color 0.3s;
  }
  
  .progress-button:hover {
    background-color: #edaf59;
  }

  /* Media queries */
  @media screen and (max-width: 768px) {
    .progress-summary-content {
      flex-direction: column;
      align-items: center;
    }
  
    .progress-info, .current-journey-container, .continue-journey-container {
      min-width: 100%;
      margin: 0.5rem 0;
    }
  
    /* .progress-summary-container {
      overflow-x: auto;
    }
   */
    .progress-title {
      white-space: nowrap;
      font-size: 1.75rem;
    }

    .current-journey-title, .current-journey-card {
        margin-right: 0;
    }

    .continue-journey-container {
        margin-right: 15px;
    }
  
    .current-journey-card {
        width: fit-content;
    }
    /* .progress-summary-content {
      display: flex;
      align-items: flex-start;
      overflow-x: auto;
    } */
  }
  