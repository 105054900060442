.courses-home {
    padding: 20px;
    background: radial-gradient(circle, #423025, #7f6c63);
    text-align: left;
  }
  
  .courses-home h5 {
    color: #3d4c63;
    font-family: Arial Rounded MT, sans-serif;
    font-size: 1rem;
  }
  
  .course-home-title {
    display: inline-block;
    padding: 0.5rem 1rem;
    clip-path: polygon(
      0% 0%, 10% 5%, 20% 0%, 30% 5%, 40% 0%, 50% 5%, 60% 0%, 70% 5%, 80% 0%, 90% 5%, 100% 0%,
      100% 100%, 90% 95%, 80% 100%, 70% 95%, 60% 100%, 50% 95%, 40% 100%, 30% 95%, 20% 100%, 10% 95%, 0% 100%
    );
    margin-bottom: 1rem;
    font-family: Arial Rounded MT, sans-serif;
    font-weight: 700;
    color: white;
    background-color: #3d4c63;
    border-radius: 10px;
    font-size: 2.5rem;
  }
  
  .courses-home-container {
    padding: 20px;
    margin: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #eeefdb;
    box-shadow: -7px 8px 10px rgb(29, 1, 1);
    flex: 4;
    overflow: auto;
    height: 100vh;
  }
  
  .module-home-title,
  .submodule-home-title {
    text-align: left;
    font-family: Arial Rounded MT, sans-serif;
    font-weight: 700;
    color: #3d4c63;
  }
  
  .module-home-title {
    margin-bottom: 1.5rem;
    font-size: 2rem;
  }
  
  .submodule-home-title {
    margin: 1.5rem 0 0.5rem;
    font-size: 1.17em;
  }
  
  .submodule {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #e0e0e0;
  }
  
  .submodule-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 0.5rem;
    color: #313639;
  }
  
  .completion-time {
    font-size: 16px;
    color: #555;
    margin-right: 0.5rem;
    margin-top: 0.2rem;
  }
  
  .units-container {
    margin: 5px 0 1rem;
  }
  
  .unit-box {
    padding: 20px;
    background-color: #e2e2b5;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: background-color 0.2s ease;
    cursor: pointer;
    margin: 1.5rem 0;
  }
  
  .unit-box.coming-soon {
    opacity: 50%;
    pointer-events: none;
  }
  
  .unit-top-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .unit-title {
    color: #313639;
    font-size: 0.83em;
    font-weight: bold;
    display: flex;
    align-items: center;
    overflow-x: auto;
  }
  
  .challenge_difficulty_tag,
  .challenge_type_tag {
    margin-top: 5px;
    margin-right: 4px;
    padding: 5px 10px;
    border-radius: 10px;
    width: fit-content;
    font-weight: 100;
  }
  
  .challenge_difficulty_tag.straightforward {
    background-color: #02b875;
    color: white;
  }
  
  .challenge_difficulty_tag.moderately_challenging {
    background-color: #f6cd61;
    color: white;
  }
  
  .challenge_difficulty_tag.challenging {
    background-color: #e66262;
    color: white;
  }
  
  .challenge_type_tag.algorizing {
    background-color: #f9d9a8;
    color: black;
  }
  
  .challenge_type_tag.coding {
    background-color: #0097a9;
    color: white;
  }
  
  .challenge_type_tag.tutorial {
    background-color: #b9bbb9;
    color: white;
  }
  
  .challenge_type_tag.low_level_design {
    background-color: #f58090;
    color: white;
  }
  
  .challenge_type_tag.system_design {
    background-color: #c64f4c;
    color: white;
  }
  
  .challenge_type_tag.knowledge {
    background-color: #9370db;
    color: white;
  }
  
  .challenge-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 5px;
  }
  
  .time-limit {
    color: gray;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  
  .time-limit-label {
    margin-right: 5px;
  }
  
  .tags {
    display: flex;
    align-items: center;
  }
  
  @media (max-width: 575px) {
    .unit-box {
      justify-content: center;
    }
    .unit-top-row {
      flex-direction: column;
    }
    .unit-title {
      justify-content: center;
    }
    .start-challenge-button {
      margin-top: 0.5rem;
    }
    .challenge-info {
      flex-direction: column;
    }
  }
  
  @media (max-width: 500px) {
    .tags {
      flex-direction: column;
    }
  }
  
  .courses-sidebar-container {
    background-color: #e2e2b5;
    flex: 1;
    box-shadow: -5px 8px 15px rgb(29, 1, 1);
    margin: 1rem 5px;
    border-radius: 10px;
    height: 100vh;
    overflow-y: scroll;
  }
  
  .main-container {
    display: flex;
    flex-direction: column;
  }
  
  @media (min-width: 1024px) {
    .main-container {
      flex-direction: row;
    }
  }
  
  .courses-sidebar {
    background-color: #333;
    color: white;
    padding: 20px;
    box-sizing: border-box;
    height: 90vh;
    position: sticky;
    top: 0;
    overflow-y: auto;
  }
  