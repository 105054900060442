.challenge-description {
    margin: 1rem;
    padding: 1rem;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.challenge-description-top-row {
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
    align-items: center;    
}

.unit-title2 {
    display: flex;
    color: #313639;
    overflow-x: auto;
    font-size: 1.17em;
    font-weight: bold;
    text-align: left;
}

.title-difficulty {
    display: flex;
    align-items: center;
}
.module-title, .submodule-title {
    margin: 0;
    padding: 8px 16px;
    text-align: left;
    font-weight: bold;
}

.submodule-title{
    font-size: 0.67rem;
}

.unit-title {
    text-align: left;
}

.challenge-title {
    margin-top: 2rem;
    text-align: left;
}

.unit-subtitle {
    text-align: left;
    margin-top: 5px;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e0e0e0;
    color: gray;
    font-weight: lighter;
}

.unit-subtitle-tag {
	border-radius: 10px;
	background-color: #d2d2d2;
	padding: 2.5px 5px;
	margin-right: 5px;
	color: white;
	font-size: 17px;
}

h1{
    font-family: 'Arial Rounded MT', sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
}

h2{
    font-family: 'Arial Rounded MT', sans-serif;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
  
p{
    font-size: 1.1rem;
    line-height: 1.5;
    color: #555;
    margin-bottom: 1.5rem;
}

ul{
    list-style: disc;
    line-height: 1.5;
}

.problem-statement {
    margin-top: 1rem;
}

.problem-statement h2 {
    text-align: left;
    font-size: 24px;
}

.problem-statement h3 {
    text-align: left;
    font-size: 22px;
    font-weight: bold;
}

.problem-statement h4 {
    text-align: left;
    font-size: 20px;
}

.problem-statement pre{
    line-height: 1.5;
    font-family: monospace;
    font-size:medium;
}

.penalty-title {
    text-align: left;
    font-weight: 600;
    color: #333333
}


.reward-title  {
    text-align: left;
    font-weight: 600;
    color: #333333 
}

.reward-icon {
    width: 32px;
    height: 32px
}

.badge-reward, .xp-reward, .special-ability-reward {
    display: flex;
    align-items: center;
}

.hint-trigger {
    display: flex;
    background-color: #333;      /* change this to your desired background color */
    color: #fff;                 /* change this to your desired text color */
    padding: 5px 10px;
    border-radius: 25px;
    cursor: pointer;             /* make it look clickable */
    transition: background-color 0.2s; /* smooth transition for hover effect */
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.hint-trigger:hover {
    background-color: #555;      /* change this to your desired hover color */
}

@media (max-width: 623px) {
    .unit-title2{
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
    }
    .title-difficulty {
        flex-direction: column;
        align-items: center;
    }
    .challenge-description-top-row {
        flex-direction: column;
        align-items: center;
    }
    .unit-subtitle{
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 0.2rem;
    }
}