/* Add this to your existing CSS file, e.g. src/css/FooterPages.css */
.page-content {
  margin-left: 2rem;
  margin-right: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 2rem;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.page-content h1, .page-content h2, .page-content h3, .page-content h4, .page-content h5, .page-content h6 {
  color: #0056b3;
}

.privacy-h1 {
  text-align: center;
  font-size: 2.5rem;
  font-family: Arial Rounded MT, sans-serif;
  font-weight: 700;
  margin-bottom: 0.8rem;
}

.privacy-h2 {
  text-align: center;
  font-size: 2rem;
  font-family: Arial Rounded MT, sans-serif;
  font-weight: 700;
  margin-bottom: 0.8rem;
}

.privacy-p {
  color: #555;
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}