/* interview-mode-selection */

/* centre all header tags*/
h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
}

.interview-selection-page-container {
  margin: 0 30px;
}

.mock-interview-page-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mode-subtitle {
  display: inline-flex;
  /* Change from flex to inline-flex */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  background-color: #28a5b3;
  color: #fff;
  width: fit-content;
  text-align: center;
}



.interview-selection-container {
  margin-top: 2rem;
  padding: 10px 40px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

/* child of .interview-selection-container div that is p tag*/
.interview-selection-container > p {
  text-align: center;
}


.filter-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.interview-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.interview-cards-container h4 {
  color: #555;
}

.interview-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.interview-card.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.mode-title2{
  text-align: center;
  font-family: Arial Rounded MT, sans-serif;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #333;
  font-size: 2.5rem;
}
/*css for span with classNAme of comingSoon that is a child of .interview-card.disabled*/
.comingSoonTag {
  margin-left: 8px;
  margin-bottom: 1rem;
  padding: 2px 4px;
  background-color: #fef6a5;
  color: #333;
  border-radius: 2px;
  font-size: 12px;
}

.experimentalTag {
  margin-left: 8px;
  margin-bottom: 1rem;
  padding: 2px 4px;
  background-color: #fea5a5;
  color: #333;
  border-radius: 2px;
  font-size: 12px;
}

.interview-card.disabled .comingSoonTag {
  margin-left: 0px;
  margin-bottom: 2px;
  font-size: 14px;
  padding: 0px 0px;
}

.interview-card.enabled {
  opacity: 1;
  cursor: pointer;
}


.interview-card:hover {
  background-color: #e0e0e0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);
}

.interview-card img {
  max-width: 100px;
  margin-bottom: 1rem;
}

.interview-card-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.start-interview-button {
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transition: background-color 0.2s, box-shadow 0.2s;
}

.start-interview-button:hover {
  background-color: #66bb6a;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.page-number {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  cursor: pointer;
}

.page-number.active {
  background-color: #28a5b3;
  color: #fff;
  border-color: #28a5b3;
}

/*mobile view*/
@media (max-width: 600px) {
  .filter-container {
    display: flex;
    display: none; /* NEBUG: unhide filter container on mobile view when you have enough content */
    flex-direction: column;
    justify-content: center;
    gap: 0 rem;
    margin-bottom: 0.5rem;
  }
}