.feedback-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-right: 5rem;
    margin-left: 5rem;
    background-color: #fafafa;
    padding: 1rem;
    text-align: left;
    height: 100%;
  }

.feedback-container-title {
    text-align: left;
    font-family: Arial Rounded MT, sans-serif;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #333;
    font-size: 2.5rem;
}
  
.assessment-box {
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 20px;
    background-color: #fff;
    margin: 10px 0;
    width: 100%;
}

.assessment-top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.assessment-top-left-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.assessment-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.assessment-unit-title-and-ribbon-container {
    display: flex;
    margin-bottom: 0.5rem;
}
.assessment-unit-title-and-ribbon {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0;
    font-size: 1.17em;
    font-weight: bold;
}

.assessment-challenge-title-container {
    margin-bottom: 2rem;
}
.assessment-challenge-title {
    font-weight: lighter;
    font-style: italic;
    color: #5A5A5A;
    font-size: 0.83em;
}

.feedback-solution-item {
    list-style-type: none;
}

.answer-correctness-symbol-container {
    display: flex;
    align-items: center;
}

.correct-icon {
    color: green;
    margin-left: 1rem;
}

.incorrect-icon {
    color: red;
    margin-left: 1rem;
}

.challenge-type {
    display: flex;
    align-items: center;
    font-size: 18px;
    text-align: left;
    margin-right: 2rem;
}

.solution-title {
    text-align: left;
}

.solution ul {
    list-style-type: none;
    margin-left: 20px;
}

.solution pre {
    background-color: #f7f7f7;
    padding: 10px;
    border-radius: 5px;
    overflow-x: auto;
}

@media (max-width: 767px) {
    .feedback-container {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width: 575px) {
    .assessment-top-left-container {
        flex-direction: column;
    }
    .challenge-type {
        margin-right: 0;
    }
    .assessment-unit-title-and-ribbon-container {
        width: 100%;
    }
    .assessment-unit-title-and-ribbon {
        flex-direction: column;
        display: flex;
        width: 100%;
    }
    .assessment-challenge-title-container {
        width: 100%;
    }
    .assessment-top-row {
        flex-direction: column;
    }
    .answer-correctness-symbol-container {
        margin-top: 2rem;
        justify-content: flex-start;
    }
}