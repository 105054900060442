.features-container {
    margin: 0 auto;
    padding: 20px;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
}

.features-by-service-container {
    font-family: 'Comic Sans MS', 'Comic Sans', 'Nunito', 'Verdana', cursive, sans-serif;
    border-radius: 10px;
    border: 1px solid gray;
    box-shadow: 0 4px 8px rgb(32, 4, 4);
    padding: 20px 35px;
    background-image: linear-gradient(135deg, #e0f1fd 20%, #c6e3fa 80%, #a0c7e6 100%);
    /* justify-content: center; */
    text-align: center;
}

.features-by-service-container .service-feature{
    text-align: left;
}

@media (max-width: 768px) {
    .features-grid {
        grid-template-columns: 1fr;
    }
}
