.company-selection-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.company-selection-container .subtext {
  text-align: center;
  justify-content: center;
}

.company-selection-heading {
  font-size: 1.6rem;
  text-align: center;
  font-weight: bold;
  margin-bottom: 16px;
  color: #333;
}

.company-flex-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px; 
  width: 100%;
  margin-top: 5px;
}

.company-flex-item {
  flex: 0 1 calc(33.33% - 12px);
  max-width: 160px;
  box-sizing: border-box;
}

.company-selection-button {
  width: 100%;
  height: 120px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.company-selection-button:hover {
  transform: translateY(-4px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.company-booking-logo {
  width: 120px;
  height: auto;
  object-fit: contain;
}

.company-no-logo {
  font-size: 0.8rem;
  font-weight: bold;
  color: #555;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Arial Rounded MT", sans-serif;
  text-transform: none;
}

@media (max-width: 768px) {
  .company-flex-item {
    flex: 0 1 calc(50% - 8px); /* Two items per row */
    max-width: 140px; /* Adjusted for tablet view */
  }

  .company-selection-button {
    height: 100px;
  }

  .company-booking-logo {
    width: 90px;
  }
}

@media (max-width: 480px) {
  .company-flex-item {
    flex: 0 1 100%; 
    max-width: 100%;
  }

  .company-selection-button {
    height: 90px;
  }

  .company-booking-logo {
    width: 90px;
  }
}
