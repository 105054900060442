.whiteboard-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
  
  .whiteboard-dropdown {
    display: flex;
    align-items: center;
    padding: 7.4px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .editor-label {
    margin-right: 10px;
    margin-left: 15px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .editor-select {
    padding: 5px;
    border: 2px solid #333333;
    border-radius: 8px;
    font-size: 16px;
    width: 200px;
  }
  
  .whiteboard-iframe {
    border: none; /* Remove iframe borders */
    flex-grow: 1; /* Allow iframe to take up all available space */
    background-color: white; /* Ensure the background matches the rest of the app */
  }
  
  @media screen and (max-width: 600px) {
    .whiteboard-container {
      width: 89.8vw;
    }
  }
  
  @media screen and (max-width: 500px) {
    .whiteboard-container {
      width: 87vw;
    }
  }
  
  

  