.timer {
    display: flex;
    align-items: center;
}

.pause-play-icon {
  margin-left: 3.5rem;
  cursor: pointer;
  align-items: center;
  display: flex;
}

.countdown-timer-text {
  color: black;
  font-weight: 500;
}