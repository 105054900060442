/* .welcome-message {
    grid-column: span 4;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
    margin-left: 30px;
    margin-bottom: 30px;
    margin-right: 30px;
}

.welcome-header {
    grid-column: span 8;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    margin-right: 30px;
    
}

.continue-button-container {
    grid-column: span 12;
    grid-row-start: 2;
    margin-top: 10px;
}

.continue-button {
    background-color: #e0e0e0;
    color: #333;
}

.sections {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px;
    grid-auto-columns: 1fr;
    margin-right: 30px;
    margin-left: 30px;
}
  
.section-content {
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 4px;
    word-wrap: break-word;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16);
}
.section-content .float-left {
    float: left;
}

.section-content .float-right {
    float: right;
    color: #888888;
}

.header {
    text-align: left;
    margin-bottom: 10px;
}

.progress{
    clear: both;
    position: relative;
    margin: auto;
    text-align: center;
    overflow: hidden;
}
  
@media (min-width: 972px) {
    .section-content:nth-child(1) {
        grid-column: span 4;
    }
    
    .section-content:nth-child(2) {
        grid-column: span 4;
    }
    
    .section-content:nth-child(3) {
        grid-column: span 4;
    }
    
    .section-content:nth-child(4) {
        grid-row-start: 2;
        grid-column: span 12;
    }
    
    .section-content:nth-child(5) {
        grid-row-start: 3;
        grid-column: span 12;
        margin-bottom: 30px;
    }
}

@media (min-width: 517px) and (max-width: 971px) {
    .section-content {
        grid-column: span 12;
    }
    .section-content:nth-child(5) {
        margin-bottom: 30px;
    }
}

@media (max-width: 516px) {
    .section-content {
        grid-column: span 12;
    }
    .section-content:nth-child(5) {
        margin-bottom: 30px;
    }
    .site-logo-container {
        grid-column: span 2;
    }
} */

@import 'tailwind.css';

html {
  font-family: 'Times New Roman', Times, serif;
}

.dashboard{
    width: -webkit-fill-available;
    margin-left: 50px;
}

.page-header{
    display: flex;
    font-weight: 700;
    font-size: 1.12rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 1;
}