.code-editor-navbar {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-x: scroll;
  max-height: 3em;
  overflow-y: hidden;
}

.code-editor-box .language-selector {
  display: flex;
  flex-direction: row;
  padding: 5px;
  border: solid 2px #333333;
  border-radius: 8px;
  color: #333;
  font-size: 16px;
}

.timer-submit-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;
}

.code-nav-btns {
  display: flex;
  flex-direction: row;
}

.code-editor-timer {
  margin-right: 2rem;
}
.code-editor-timer.code-editor-timer.extra-margin-right {
  margin-right: 4rem;
}

.code-editor-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.reset-button {
  margin-right: 6px;
  padding-left: 21px !important;
  padding-right: 21px !important;
  height: 35px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: inherit;
  background-color: #e0e0e0;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
}

.run-button,
.submit-button {
  max-height: 37px;
  color: rgb(255, 255, 255);
  margin-right: 10px;
  padding: 10px;
  font-weight: 500;
  font-size: 0.875rem;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.run-button {
  background-color: #0097a8;
  min-width: 70px;
}

.submit-button {
  background-color: #1976d2;
  min-width: 90px;
}

.run-button:hover {
  background-color: #45a049;
}

span.Resizer.vertical {
  background: red;
  cursor: col-resize;
  width: 20px;
  min-height: 20px;
}

span.Resizer.horizontal {
  background: red;
  height: 20px;
}

@media (max-width: 499px) {
  .code-editor-timer > .timer {
    margin-left: 4rem;
    margin-right: 1rem;
  }

  .code-editor-timer.extra-margin-right > .timer {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}

@media (max-width: 589px) {
  .code-editor-box {
    width: 100vw;
  }
  .code-editor-navbar {
    width: 89vw;
  }
}
