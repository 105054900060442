:root {
    --primary-color: #007bff;
    --primary-hover-color: #0056b3;
    --background-color: #e2e2b5;
    --background-light: #f5f5c8; /* Lighter shade */
    --background-dark: #b8b87d;  /* Darker shade */
    --border-radius: 0.75rem;
}

/* Sidebar Wrapper with a more pronounced gradient using light and dark variations */
.sidebar-wrapper {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 1rem;
    border-radius: var(--border-radius);
    background: linear-gradient(135deg, var(--background-dark), var(--background-color), var(--background-light));
    box-shadow: 0 -3px 6px rgba(10, 9, 9, 0.1);
}

/* Sidebar Container */
.sidebar-container {
    display: flex;
    flex-direction: column;
    margin: 1.2rem;
    font-family: "Arial Rounded MT", sans-serif;
    border-radius: var(--border-radius);
    font-weight: 500;
    gap: 0;
}

/* Sidebar Section remains unchanged */
.sidebar-section {
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
    padding: 1rem;
    margin: 1.5rem auto;
    max-width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--background-color);
}

.sidebar-section h6 {
    font-family: "Arial Rounded MT", sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: #3d4c63;
}

.sidebar-section p {
    font-size: 0.875rem;
    text-align: center;
}

/* These styles are for links; if you're replacing them with buttons, they may not be used */
.sidebar-section a {
    background-color: var(--primary-color);
    justify-content: center;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius);
    text-decoration: none;
    display: inline-block;
    text-align: center;
}

.sidebar-section a:hover {
    background-color: var(--primary-hover-color);
    color: white;
}

.sidebar-section a:focus {
    outline: 2px solid var(--primary-hover-color);
}

.section-link {
    margin-top: -20px;
    display: flex;
    justify-content: center;
}

/* Bounce animation for sidebar buttons */
@keyframes bounceIn {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    20% {
        transform: translateY(-10%);
        opacity: 1;
    }
    40% {
        transform: translateY(5%);
    }
    60% {
        transform: translateY(-5%);
    }
    80% {
        transform: translateY(2%);
    }
    100% {
        transform: translateY(0);
    }
}

.sidebar-button {
    animation: bounceIn 1s ease-out;
}

/* Responsive adjustments */
@media screen and (max-width: 1023px) {
    .sidebar-container {
        flex-direction: row;
        gap: 0.8rem;
    }
}

@media screen and (max-width: 523px) {
    .sidebar-container {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0rem;
    }
}
