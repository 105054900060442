.game-navbar {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, minmax(50px, 100px));
  grid-gap: 20px;
  background-color: #f2f2f2;
  border-radius: 4px;
  word-wrap: break-word;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 30px;
  align-items: center;
}

.game-navbar-icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: stretch;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: gray;
}
.progress-bar-box {
    grid-column: span 4;
}

.hamburger-icon-container {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.powerups-container {
  display: grid;
  grid-template-columns: repeat(10, 64px);
  text-align: center;
  grid-column: span 6;
}
.powerup-text {
  font-size: 0.8rem;
}

.remaining-units {
  color: darkgray
}

.heart-container {
  display: flex;
}

.heart-icon-with-text {
  display: flex;
  align-items: center;
}

.heart-icon-text {
  margin-right: 1rem;
  color: red;
}

.challenge-lives {
  white-space: nowrap;
}

.heart-icons {
  display: flex;
}

.heart-icon {
  color: red;
}

@media (max-width: 720px) {
  .powerups-container {
    grid-template-columns: repeat(5, 64px);
  }
}

@media (max-width: 1250px) {
  .progress-bar-box {
    grid-column: span 8;
  }
  .powerups-container {
    grid-row-start: 2;
    justify-content: center;
    grid-column: span 12;
  }
}

@media (max-width: 750px) {
  .progress-bar-box {
    grid-column: span 6;
  }
}
@media (max-width: 600px) {
  .progress-bar-box {
    grid-column: span 3;
  }
  .powerups-container {
    grid-row-start: 2;
    justify-content: center;
    grid-column: span 8;
  }
}
@media (max-width: 450px) {
  .progress-bar-box {
    grid-column: span 2;
  }
  .powerups-container {
    grid-row-start: 2;
    justify-content: center;
    grid-column: span 6;
  }
}