.bento-box {
    display: grid;
    grid-template-columns: repeat(3, minmax(min-content, max-content));
    grid-template-areas:
      "feature1 feature1 feature2"
      "feature3 feature4 feature4"
      "feature5 feature5 feature6";
    grid-gap: 20px;
    justify-content: center;
    padding: 40px 0;
  }
  
  .item1 {
    grid-area: feature1;
    display: flex;
    gap: 50px;
  }
  
  .item1 > .itemText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .item2 {
    grid-area: feature2;
    display: flex;
    gap: 10px;
    width: fit-content;
  }
  
  .item2 > .itemText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 170px;
  }
  
  .item3 {
    grid-area: feature3;
    display: flex;
    gap: 10px;
    width: fit-content;
  }
  
  .item3 > .itemText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 170px;
  }
  
  .item4 {
    grid-area: feature4;
    display: flex;
    gap: 50px;
  }
  
  .item4 > .itemText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .item5 {
    grid-area: feature5;
    display: flex;
    gap: 50px;
  }
  
  .item5 > .itemText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .item6 {
    grid-area: feature6;
    display: flex;
    gap: 10px;
    width: fit-content;
  }
  
  .item6 > .itemText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 170px;
  }
  
  .itemText h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
  }
  
  .grid-item {
    border: 1px solid black;
    height: fit-content;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    z-index: 3;
    background-color: rgba(74, 50, 47, 0.8);
  }
  
  .grid-item:hover {
    transform: translateY(-5px);
  }
  
  .grid-item h3 {
    color: #f3ecd8;
    margin: 0;
  }
  
  .grid-item p {
    color: #f4d17a;
    margin: 0;
  }
  
  .grid-item a {
    color: #b0e0e6;
    text-decoration: none;
  }
  
  .grid-item img {
    height: 150px;
    width: 150px;
  }
  
  @media (max-width: 800px) {
    .bento-box {
      align-items: center;
      padding: 20px;
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: none;
    }
  
    .grid-item {
      gap: 0;
      grid-area: auto;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      height: auto;
      position: relative;
    }
  
    .grid-item .itemText {
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.1);
      color: white;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 10px;
    }
  
    .grid-item .itemText.active {
      background: rgba(0, 0, 0, 0.5);
    }
  
    .grid-item .itemText h3 {
      font-size: 1.1em;
      color: rgba(255, 255, 255, 0.9);
    }
  
    .grid-item .itemText h3.active {
      color: rgba(255, 255, 255, 1);
    }
  
    .grid-item .itemText .box p {
      font-size: 0.9rem;
    }
  
    .grid-item .itemText .box a {
      font-size: 0.8rem;
    }
  
    .grid-item .itemText .box.active {
      display: block;
    }
  
    .grid-item .itemText .box {
      display: none;
    }
  }
  