:root {
  --content-box-min-width-value: 520px;
}

.content-grid-element, .user-response-grid-element {
  box-sizing: border-box;
}

.boxes-container {
  display: flex;
  gap: 10px;
  margin: 0;
  margin-bottom: 1rem;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 2rem;
  overflow: auto;
  width: auto;
  height: calc(100vh - 2px); /* Fixed the height calculation for iOS devices */
}

.output-box-component {
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Ensure scrolling works properly */
  height: 100%; /* Keep this to ensure the content box fits the parent */
}

.content-grid-element {
  display: flex;
  background-color: #f2f2f2;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16);
  word-wrap: break-word;
  align-items: center;
  overflow: scroll;
  height: auto; /* Keep height auto but allow it to grow as needed */
  min-height: 500px;
  min-width: var(--content-box-min-width-value);
}

.user-response-grid-element {
  display: flex;
  flex-grow: 1;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16);
  word-wrap: break-word;
  align-items: center;
  min-width: var(--content-box-min-width-value);
}

.buttons-container {
  grid-row: 2;
  grid-column: 1 / span 2; 
}

.start-challenge-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
  cursor: pointer;
  justify-content: center;
}

.courses-container > .modal-container > .modal > .modal-content {
  height: 100%;
}

.explanations-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.explanations-view{
  height: 51px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.explanations-view{
  width: 100%;
  margin-top: 10px;
  height: 100%;
  position: relative;
  overflow-y: scroll;
}

.blurred-content {
  filter: blur(4px);
  transition: filter 0.3s ease;
}

/* Optional: If you want to define an overlay container style */
.overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Optionally add a semi-transparent background if desired */
  background-color: rgba(255, 255, 255, 0);
}


.failure-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.failure-buttons-container {
  display: flex;
}

span.Resizer.vertical {
  background: rgb(209, 22, 159) !important;
  cursor: col-resize !important;
  width: 3px !important;
  max-height: 20px !important;
}

span.Resizer.horizontal {
  background: rgb(220, 13, 151);
  height: 20px;
}

@media (max-width: 1100px) {
  .boxes-container {
  height: calc(160vh - 2px); 
}

}

@media (max-width: 768px) {
    .boxes-container {
    display: flex;
    gap: 10px;
    margin: 0;
    margin-bottom: 1rem;
    justify-content: center;
    margin-left: 2rem;
    margin-right: 2rem;
    overflow: auto;
    width: auto;
    flex-direction: column;
    height: calc(220vh - 2px); 
    
}

  .content-grid-element{
    resize: none;
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    min-width: 100%;
  }
  
  .user-response-grid-element {
    grid-column: 1;
    grid-row: 2;
    width: 100%;
    min-width: 100%;
  }

  .buttons-container {
    grid-column: 1 / span 2; 
    grid-row: 3;
  }

  .explanations-view {
    margin: 0;    /* Remove any margins that could offset it */
  }
  

  span.Resizer.vertical {
    background: rgb(209, 22, 159) !important;
    cursor: col-resize !important;
    width: 3px !important;
    max-height: 20px !important;
  }
  
  span.Resizer.horizontal {
    background: rgb(220, 13, 151);
    height: 20px;
  }
}
