.premium-subscription-modal {
    background: linear-gradient(135deg, #f3e5f5, #e8f5e9);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.premium-subscription-modal .root-container {
    background: transparent;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.premium-subscription-modal h1, 
.premium-subscription-modal p {
    color: #333;
    margin: 10px 0;
    text-align: center;
}

.premium-subscription-modal button {
    border: none;
    color: white;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.subscription-container{
    margin: 0px;
}

.subscription-form-view{
    border-radius: 0px;
    padding: 40px;
    box-shadow: 0 2px 5px rgba(10, 4, 4, 0.938);
}