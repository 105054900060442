.progress-bar-container {
    height: 20px;
    background-color: #e0e0de;
    border-radius: 50px;
  }
  
  .progress-bar-filler {
    height: 100%;
    background-color: orange;
    border-radius: inherit;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
  }
  
  .progress-bar-label {
    padding: 5px;
    color: white;
    font-weight: bold;
  }
  
  @media (max-width: 575px) {
    .progress-bar-label {
      display: none;
    }
}