.post-interview-feedback-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 500px;
  margin: auto;
}

.post-interview-feedback-modal-container form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.post-interview-feedback-modal-container form label {
  margin-bottom: 10px;
}

.post-interview-feedback-modal-container form input,
.post-interview-feedback-modal-container form textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.post-interview-feedback-modal-container form button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007BFF;
  color: #fff;
  cursor: pointer;
  margin-top: 10px;
}

.post-interview-feedback-modal-container form button:hover {
  background-color: #0056b3;
}

.post-interview-feedback-modal-container h1 {
  margin-top: 20px;
  color: #333;
}

.post-interview-feedback-modal-container p {
  color: #666;
}

.post-interview-feedback-modal-container form select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.post-interview-feedback-modal-container form select option {
  padding: 10px;
}

.post-interview-feedback-modal-container form select:focus {
  outline: none;
  box-shadow: 0px 0px 5px #007BFF;
}

.post-interview-feedback-modal-container form select::-ms-expand {
  display: none;
}

.required-field {
  color: #0056b3;
  /* darker blue */
}

.optional-field {
  color: #ccc;
  /* light gray */
}