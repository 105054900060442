/* src/utils/Spinner.css */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Aligns the spinner at the top */
    height: 100vh; /* Full viewport height */
    padding-top: 20px; /* Optional: Adds some spacing from the top */
    box-sizing: border-box; /* Ensures padding is included in the height */
  }
  
  .spinner {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  