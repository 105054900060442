/* src/css/Layout.css */

.layoutPage {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

nav {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 1px;
}

nav ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
}

nav li {
    margin: 0 1rem;
}


main {
    flex: 1;
}


.mobile-alert{
    display: none;
}

@media (max-width: 600px) {
    nav li {
        margin: 0px;
    }
    .mobile-alert {
        display: block;
      }
}

