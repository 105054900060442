.explanations-container {
    height: 100%;
    width: 100%;
}

.explanations-content-container {
    margin-top: 2rem;
    overflow-y: auto;
}

.key-associations-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
.key-association-icon {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #f2f2f2;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}
.key-association-icon:hover {
    background-color: #e0e0e0;
}