.booking-type-container h1 {
    margin-bottom: 0px;
}

.recommendedTag {
    background-color: #20dbc9; /* Bright yellow background */
    color: white; 
    padding: 0.2rem 0.5rem; /* Padding around the text */
    border-radius: 0.3rem; /* Rounded corners */
    font-weight: bold; /* Bold text */
    margin-left: 1px; /* Small margin on the right */
    font-size: 0.9rem; /* Slightly smaller font size */
    display: inline-block; /* Ensures proper spacing and alignment */
}

.booking-and-payment-container {
    margin: 0 auto;
    padding: 20px;
}

.booking-and-payment-container .multiple-payment-buttons{
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    justify-content: center;
}

.booking-and-payment-container .money-back-guarantee {
    padding: 0 20px;
    border-radius: 10px;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
}

.booking-and-payment-container .money-back-guarantee p {
    color: #333;;
}

.booking-and-payment-container  .payment-button {
    margin-bottom: 10px;
}

.booking-and-payment-container .price-tag{
    font-size: 1.2rem;
}

.booking-and-payment-container .price-tag-subtitle{
    font-size: 0.9rem;
    border-radius: 5px;
    width: fit-content;
    padding: 1px 5px;
    margin: 1px auto;
    color: white;
    background-color: black;
}

.booking-options-container {
    display: flex; /* Enable flexbox */
    flex-direction: column;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    margin: auto; /* Center the container itself if needed */
}

.payment-options-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 40px;
}

.booking-type-container {
    font-family: 'Comic Sans MS', 'Comic Sans', 'Nunito', 'Verdana', cursive, sans-serif;
    border-radius: 10px;
    border: 1px solid gray;
    box-shadow: 0 4px 8px rgb(20, 8, 8);
    padding: 20px 25px;
    background-image: linear-gradient(135deg, #e0f1fd 20%, #c6e3fa 80%, #a0c7e6 100%);
    /* justify-content: center; */
    text-align: center;
}

.booking-type-container .service-feature {
    text-align: left;
}

.booking-type-container .feature-header{
    height: 120px;
}

@media (max-width: 1400px) {
    .booking-type-container .feature-header{
        height: 180px;
    }
    
}

@media (max-width: 1100px) {
    .payment-options-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .booking-type-container .feature-header{
        height: 160px;
    }
    
}

@media (max-width: 900px) {
    .booking-type-container .feature-header{
        height: 180px;
    }
    
}

@media (max-width: 768px) {
    .payment-options-grid {

        /*single column*/
        grid-template-columns: 1fr;
    }
    
}