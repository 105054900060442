.ribbon {
    border-radius: 12px;
    padding: 6px 12px;
    border: none;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 0.9rem;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
}
.green {
    background-color: green;
}

.yellow {
    background-color: #f0c40e;
}

.red {
    background-color: red;
}

.orange{
    background-color: #e67321;
}

.blue{
    background-color: #1976d2;
}