.disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.shown {
  cursor: pointer;
  pointer-events: auto;
  opacity: 1;
}

.list-container {
  width: 100%;
  list-style-type: none;
  padding: 1rem;
  border-bottom: 1px solid #e0e0e0
}

.list-title-container {
  height: 100%;
  padding: 12px;
}

ol{
  list-style-type: decimal;
}

.list-container.hovered {
  background-color: #e0e0e0;
}

.dropdown {
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  list-style-type: none;
  padding-left: 0;
  margin: 1rem;
  cursor: auto;
  padding: 12px;
  overflow-x: auto;
  overflow-y: hidden;
}

.explanations-items .dropdown{
  margin: 0.5px;
  padding: 25px;
}

.explanations-items body{
  background-color: white;
}

.explanations-items h1{
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-bottom: .3em;
  font-size:2em;
  border-bottom: 1px solid #d0d7deb3;
  font-weight: 600;
  line-height: 1.25;
  margin: .67em 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  word-wrap: break-word;
}

.explanations-items h2{
  padding-bottom: .3em;
  font-size: 1.5em;
  border-bottom: 1px solid #d0d7deb3;
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  word-wrap: break-word;
}
 
.explanations-items h3{
  font-size: 1.25em;
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  word-wrap: break-word;
}

.explanations-items h4{
  font-size: 1em;
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  word-wrap: break-word;
}

.explanations-items h5{
  font-size: .875em;
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;        
  margin-top: 24px;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  word-wrap: break-word;
}

.explanations-items p{
  margin-top: 0;
  margin-bottom: 16px;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  line-height: 1.7;
  word-wrap: break-word;
  color: black
}

.explanations-items ul{
  text-align: -webkit-match-parent;
  padding-left: 2em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  line-height: 1.7;
  word-wrap: break-word;
  margin-top: 0;
  margin-bottom: 16px;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  
}

.explanations-items ol{
  list-style-type: decimal;
  padding-left: 2em;
  margin-top: 0;
  margin-bottom: 16px;
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  line-height: 1.7;
  word-wrap: break-word;
}

.explanations-items li{
  display: list-item;
  text-align: -webkit-match-parent;
  unicode-bidi: isolate;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  line-height: 1.7;
  word-wrap: break-word;
  
}

.explanations-items ol ul{
  list-style-type: circle;
  margin-block-start: 0px;
  margin-block-end: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  line-height: 1.7;
  word-wrap: break-word;
  margin-top: 0;
  margin-bottom: 0;
}

.explanations-items ol ul ul{
  list-style-type: square;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  line-height: 1.7;
  word-wrap: break-word;
}

.explanations-items a{
  text-decoration: underline;
  text-underline-offset: .2rem;
  color: #0969da;
  background-color: transparent;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  line-height: 1.7;
  word-wrap: break-word;
}

.explanations-items strong{
  color: black;
}

@media (max-width: 589px) {

  .explanations-items .dropdown{
    width: 65vw;
    display: flex;
    align-items: center;
    margin: auto;
  }
}