/* Overall container styling */
.code-solution-viewer {
    width: 100%;
    margin: auto;
    font-family: Arial, sans-serif;
}

/* Language Tabs */
.language-tabs {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
}

.language-tab {
    background: linear-gradient(135deg, #f0f0f0, #e0e0e0);
    border: 1px solid #ddd;
    border-radius: 8px; /* Increased border radius */
    padding: 10px 15px;
    cursor: pointer;
    font-weight: bold;
    transition: background-image 0.3s, border 0.3s, box-shadow 0.3s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Added subtle shadow */
    min-width: 100px; /* Set minimum width */
    text-align: center; /* Center text within button */
}

.language-tab.selected {
    background-image: linear-gradient(135deg, #83a4d4, #91e8ec); /* Updated gradient */
    color: #fff;
    border: 2px solid #1e5aa8; /* Highlighted border */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* More prominent shadow */
}

.language-tab:not(.selected) {
    color: #555; /* More visible text for non-selected tabs */
}

.language-tab:hover {
    background-image: linear-gradient(135deg, #c1d4e8, #d1ecf5); /* Lighter shade on hover */
    /* Alternatively, you can use opacity:
    opacity: 0.9;
    */
}

/* Controls Container */
.controls-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Space between theme selector and button */
    margin-bottom: 15px;
}

/* Theme Selector */
.theme-selector {
    display: flex;
    align-items: center;
}

.theme-selector label {
    margin-right: 5px;
    font-weight: bold;
}

.theme-selector select {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    background: #fff;
    transition: border 0.3s, box-shadow 0.3s;
}

.theme-selector select:focus {
    border-color: #2b70c9;
    box-shadow: 0 0 5px rgba(43, 112, 201, 0.5);
}

/* Code Display */
.code-display {
    position: relative; /* Added to position the copy button absolutely within */
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    font-size: 14px;
    color: #333;
    max-width: 100%;
    overflow-x: auto;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

/* Copy Button Styling */
.copy-button {
    position: absolute;
    top: 10px;
    left: 10px; /* Position at top-left */
    background-color: black; /* Black background for visibility */
    color: white; /* White icon color */
    border: solid white 1px; /* White border for contrast */
    cursor: pointer;
    font-size: 18px;
    padding: 5px; /* Optional: Adds padding around the icon */
    border-radius: 4px; /* Optional: Rounded corners for a cleaner look */
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 90;
}

.copy-button:hover {
    background-color: #333; /* Slightly lighter black on hover */
    color: #f0f0f0; /* Light gray for hover contrast */
}


/* View in Modal Button */
.view-modal-button {
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 8px; /* Increased border radius */
    background-image: linear-gradient(135deg, #83a4d4, #91e8ec); /* Updated gradient */
    color: white;
    border: none;
    transition: background-image 0.3s, box-shadow 0.3s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Added subtle shadow */
}

.view-modal-button:hover {
    background-image: linear-gradient(135deg, #6a93c2, #789fc3); /* Slightly darker gradient on hover */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* More prominent shadow on hover */
}

/* Modal-specific styling */
.modal-code-display {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    max-width: 90%;
    overflow-x: auto;
    background-color: #f9f9f9; /* Optional: Background color for better aesthetics */
    border-radius: 10px; /* Optional: Rounded corners */
    position: relative; /* Ensure the copy button is positioned correctly in modal */
}

/* Optional mobile adjustments */
@media (max-width: 768px) {
    .language-tabs {
        flex-wrap: wrap;
    }
    .view-modal-button {
        font-size: 13px;
        padding: 6px 10px;
    }
    .controls-container {
        flex-direction: column;
        gap: 10px;
    }
    .language-tab {
        min-width: 80px; /* Adjusted for smaller screens if necessary */
        padding: 8px 12px;
    }
}
