.premium-content-access-denied-message {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.premium-content-access-denied-message > p {
    color: #800000;
    text-align: center;
    margin: 0;
}

.premium-subscribe-button {
    background-image: linear-gradient(189deg, #ffff99, #fdd835);
    color: black;
    text-transform: none;
    margin-top: 20px;
}
