.FAQ {
    max-width: 750px;

    border: 1px solid black;
    border-radius: 20px;
  
    align-self: center;
    display: flex;
    flex-direction: column;
    background-color: rgba(74, 50, 47, 0.7);
    position: relative;
    z-index: 3;
}

.FAQ > h2 {
    position: absolute;
    top: -50px;
    left: 10px;
    color: #333;
    font-size: 1.5rem;
    font-family: "Raleway";
    font-weight: bold;
}

.faq-item {
    border: 1px solid black;
    overflow: hidden;
    transition: all 0.3s ease;
    background-color: rgba(74, 50, 47, 0.7);
}

.faq-item .question {
cursor: pointer;
display: flex;
justify-content: space-between;
align-items: center;
padding: 20px 30px;
position: relative;
}

.faq-item .question h3 {
    margin: 0;
    font-size: 1.1rem;
    color: #f3ecd8;
    font-family: "Raleway";
    padding-right: 5px;
    text-align: left;
}

.faq-item .question .arrow {
transition: transform 0.3s ease;
}

.faq-item .answer {
max-height: 0;
opacity: 0;
padding: 0 30px;
color: #f3ecd8;
font-family: "Raleway";
transition: all 0.3s ease;
pointer-events: none; /* Prevents clicking on the answer when it's invisible */
}

.faq-item.open .answer {
    max-height: 75px;
    overflow-y: auto;
    opacity: 1;
    padding: 0 30px;
    pointer-events: all;
}

.faq-item.open .answer p {
color: #f3ecd8;
}

.faq-item.open .answer a {
color: #a135b9;
}

.faq-item:first-child {
border-top-left-radius: 18px;
border-top-right-radius: 18px;
}

.faq-item:last-child {
border-bottom-left-radius: 18px;
border-bottom-right-radius: 18px;
}

.faq-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
}

.questions {
    width: 100%;
    max-width: 800px;
}

.pagination-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin-top: 5px;
    z-index: 5;
}

.pagination-controls button {
    background-color: #f3ecd8;
    border: none;
    border-radius: 5px;
    padding: 5px 7px;
    font-size: 12px;
    border-radius: 30px;
    cursor: pointer;
    margin-left: 5px;
    transition: background-color 0.3s;
}

.pagination-controls button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.pagination-controls button:hover:not(:disabled) {
background-color: #e0d4b7; /* Slightly darker shade for hover effect */
}


.FAQ {
  max-width: 750px;
  border: 1px solid black;
  border-radius: 20px;

  align-self: center;
  display: flex;
  flex-direction: column;
  background-color: rgba(74, 50, 47, 0.7);
  position: relative;
  z-index: 3;
}

.FAQ > h3 {
  position: absolute;
  top: -40px;
  left: 10px;
  color: #f3ecd8;
  font-size: 1.5rem;
  font-family: "Raleway";
  font-weight: bold;
}

.faq-item {
  border: 1px solid black;
  /* margin-bottom: 10px; */
  /* border-radius: 20px; */
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: rgba(74, 50, 47, 0.7); /* Use your color scheme */
}

.faq-item .question {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  position: relative;
}

.faq-item .question p {
  margin: 0;
  font-size: 1.25rem;
  color: #f3ecd8;
  font-family: "Raleway";
}

.faq-item .question .arrow {
  transition: transform 0.3s ease;
}

.faq-item .answer {
  max-height: 0;
  opacity: 0;
  padding: 0 30px;
  color: #f3ecd8; /* Your text color */
  font-family: "Raleway";
  transition: all 0.3s ease;
  pointer-events: none; /* Prevents clicking on the answer when it's invisible */
}

.faq-item.open .answer {
  max-height: 80px; /* Adjust this value based on your content's needs */
  overflow-y: auto;
  opacity: 1;
  padding: 0 30px;
  pointer-events: all;
}

.faq-item.open .answer p {
  color: #f3ecd8;
}

.faq-item.open .answer a {
  color: #a135b9;
}

.faq-item:first-child {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.faq-item:last-child {
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.questions {
  width: 100%;
  max-width: 800px;
}

.pagination-controls {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 800px;
  margin-top: 5px;
  z-index: 5;
}

.pagination-controls button {
  background-color: #f3ecd8; /* Matching the modal background color */
  border: none;
  border-radius: 5px;
  padding: 5px 7px;
  font-size: 12px;
  border-radius: 30px;
  cursor: pointer;
  margin-left: 5px;
  transition: background-color 0.3s;
}

.pagination-controls button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination-controls button:hover:not(:disabled) {
  background-color: #e0d4b7;
}

/* Below universal for all FAQ for scroll bar */
.faq-item.open .answer {
    max-height: 75px; 
    overflow-y: auto;
    -ms-overflow-style: auto;
    scrollbar-width: thin;
}

/* For WebKit browsers (Chrome, Safari) scrollbar settings */
.faq-item.open .answer::-webkit-scrollbar {
    display: block;
    width: 10px;
}

.faq-item.open .answer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
}

.faq-item.open .answer::-webkit-scrollbar-track {
    background-color: lightgrey;
}

@media (max-width: 1100px) {
  .questions .faq-item .question h3 {
     font-size: 0.9rem;
  }

  .questions .faq-item .answer p{
    font-size: 1rem;
 }

  .questions .faq-item .question {
    padding: 15px 25px;
  }

  .FAQ h2 {
    top: -30px;
    font-size: 1.1rem;
  }
}
