.editor-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 0.5em 1em;
}

/* Theme selector styling */
.editor-controls .theme-selector {
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  appearance: none;
  border: 1px solid transparent;
  transition: background-color 0.3s ease;
}

/* Dark theme styles */
.editor-controls.dark,
.theme-selector.dark {
  background-color: #1e1e1e;
  color: #f4f4f4;
}

.editor-controls .theme-selector.dark {
  border-color: #444;
}

/* Light theme styles */
.editor-controls.light,
.theme-selector.light {
  background-color: #f4f4f4;
  color: #000;
}

.editor-controls .theme-selector.light {
  border-color: #ccc;
}

/* High-contrast theme styles */
.editor-controls.high-contrast,
.theme-selector.high-contrast {
  background-color: #1b1b1b;
  color: #fff;
}

.editor-controls .theme-selector.high-contrast {
  border-color: #666;
}

/* Option tags inherit background and color */
.editor-controls .theme-selector option {
  background-color: inherit;
  color: inherit;
  padding: 5px;
}

.editor-controls.dark select,
.editor-controls.high-contrast select {
  color: #4b894e;
}

/* Styling for the font size controls (buttons and text) */
.editor-controls div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.editor-controls div .icon-button {
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: #fff;
  background: none;
}

/* Button Styling Based on Theme */
.editor-controls .icon-button.light {
  color: #0010ff;
}

.icon-button.light:hover {
  color: #0813b198;
}

.editor-controls .icon-button.dark,
.editor-controls .icon-button.high-contrast {
  color: #4b894e;
}

.icon-button.dark:hover,
.icon-button.high-contrast:hover {
  color: #154819;
}

.editor-controls.light span {
  color: #0010ff;
}

.editor-controls.dark span,
.editor-controls.high-contrast span {
  color: #4b894e;
}

/* Font size display styling */
.editor-controls span {
  font-size: 16px;
  font-weight: bold;
}

/* Responsive layout for mobile */
@media screen and (max-width: 600px) {
  .editor-controls {
    align-items: center;
    gap: 32px;
  }

  .editor-controls .theme-selector {
    font-size: 18px;
    padding: 5px;
  }

  .editor-controls div {
    margin-right: 64px;
  }
}
