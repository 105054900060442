.check-solution-title {
    margin-top: 3rem;
    margin-right: 3rem;
    margin-left: 3rem;
}

.check-solution-progress {
    margin-top: 8rem;
    margin-right: 8rem;
    margin-left: 8rem;
}

.check-solution-result {
    margin-top: 8rem;
}

.check-solution-buttons {
    display: flex;
    justify-content: center;
    margin-top: 8rem;
}


/* Submission Output Summary */
.submission-execution-output-container {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.outlined-button {
    margin: 0.5rem;
    border: 1px solid #1976d2; /* Primary color */
    color: #1976d2;
    background-color: transparent;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.outlined-button:hover {
    background-color: #1976d2; /* Primary color */
    color: white;
}

.test-case-row {
    margin-top: 1rem;
    padding: 0.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: #ffffff;
}

.test-case-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
}

.test-case-summary span {
    font-weight: bold;
}

.test-case-details {
    margin-top: 0.5rem;
    padding: 0.5rem;
    background-color: #f0f0f0;
    border-radius: 4px;
}

.test-case-details p {
    margin: 0.25rem 0;
}

.stderr {
    color: red;
}

button {
    cursor: pointer;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #145a86;
}