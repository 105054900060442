/* MockHighlights.css */


.mock-highlights-container h1 {
  font-size: 2rem;
}


/* Container Styles */
.mock-highlights-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
}

.mock-highlights-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-bottom: 5px gray solid;
}

.mock-board-container {
  display: flex;
  flex-direction: column;
}

.mock-board-container h3 {
  align-self: flex-start;
  margin: 0;
}

/* Carousel Feature */
.carousel-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel-slides {
  display: flex;
  transition: transform 0.5s ease-out;
  transform: translateX(var(--carousel-translate, 0));
}

.carousel-page {
  min-width: 100%;
}

.carousel-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 10px;
  margin: 20px 0;
}

.carousel-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  padding: 0 1rem;
  z-index: 10;
}

.carousel-button {
  background-color: rgba(0, 0, 0, 0.2); /* Darker background for visibility */
  color: white;
  border: none;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.9); /* Darker on hover for better contrast */
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 10px;
}

.carousel-indicators .indicator-dot {
  width: 0.75rem;
  height: 0.75rem;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  border: none;
}

.carousel-indicators .indicator-dot.active {
  background-color: #333; /* Darker color for active indicator */
  transform: scale(1.2);    /* Slightly larger to indicate active state */
}

.carousel-indicators .indicator-dot:focus {
  outline: 2px solid #000; /* Focus outline for accessibility */
}

.no-highlights-message {
  text-align: center;
  font-size: 1.2rem;
  color: #555;
  padding: 20px;
}

/* Mock Highlight Card Styles */
.mock-highlight-card {
  display: flex;
  flex-direction: column;
  background-color: #fffb8f; /* Yellow background */
  padding: 20px;
  border-radius: 10px;
  font-family: 'Comic Sans MS', 'Comic Sans', 'Nunito', 'Verdana', cursive, sans-serif;
  position: relative;
  width: 100%;
  box-sizing: border-box; /* Include padding and border in the width */
  gap: 10px;
  transition: box-shadow 0.3s, border 0.3s;
}

.mock-highlight-card:hover{
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
  border: none;
}

.mock-highlight-card .video-thumbnail-container {
  position: relative;
  width: 100%;
  cursor: pointer;
  aspect-ratio: 16/9;
  overflow: hidden;
  background: none; /* Remove default button background */
  border: none;     /* Remove default button border */
  padding: 0;       /* Remove default button padding */
  display: block;   /* Ensure the button takes up the full width */
}

.mock-highlight-card .video-thumbnail-container:focus {
  outline: 2px solid #000; /* Provide focus outline for accessibility */
}

.video-iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px; /* Rounded borders for iframe */
  border: none;        /* Remove default iframe border */
}

/* Text Container Styles */
.mock-text-container {
  display: flex;
  flex-direction: column;
}

.mock-text-container .mock-brand {
  margin-bottom: 0.75rem;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid black;
  background-color: white;
  width: fit-content;
  font-size: 0.8rem;
  color: black;
}

.mock-text-container .mock-title {
  margin: 0;
  font-size: 1.2rem;
}

.mock-text-container .mock-caption {
  opacity: 70%;
  font-size: 0.9rem;
  overflow-wrap: break-word;
  color: black;
}

.book-session-button-container {
  display: flex;
  justify-content: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .video-thumbnail-container {
      width: auto;
      margin-right: 0;
      margin-bottom: 10px;
  }
}

@media (max-width: 820px) {
  .video-thumbnail-container {
      width: 225px;
      margin-right: 15px;
  }

  .carousel-grid {
      grid-template-columns: 1fr;
  }

  .carousel-button {
      padding: 0.4rem;
  }

  .mock-highlights-container h1 {
    font-size: 1.5rem;
  }
}
